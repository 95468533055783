import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CommentsSection from "../components/CommentsSection";
import FileUploads from "../components/FileUploads";

const user = JSON.parse(localStorage.getItem("userData"));
// const token = user?.token;
// const headers = {
//   "Content-Type": "application/json",
//   Authorization: `Bearer ${token}`,
//   "ngrok-skip-browser-warning": "skip-browser-warning",
// };

const PatientDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state;
  const [patientData, setPatientData] = useState();
  const [patientComments, setPatientComments] = useState([]);
  let patientComment;

  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem("userData"));
  const fetchPatientDetails = () => {
    // axios.post(`${process.env.REACT_APP_API_URL}api/v1/getpatientrecordById`,
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/getpatinentrecordbyidVtwo`,
        { patient_id: id },
        { withCredentials: true }
      )
      .then((response) => {
        console.log("patient-id", response);
        setPatientData(response?.data?.data?.result);
      })
      .catch((error) => {
        console.log("Network error:", error);
      });
  };

  console.log("state", patientData);
  useEffect(() => {
    fetchPatientDetails();
  }, []);

  // if (!patientData && !patientComments) {
  //   return <div>Loading...</div>;
  // }

  if (!patientData) {
    return <div className='flex justify-center w-full mt-20'>
    <svg aria-hidden="true" className="inline w-7 h-7 text-primary animate-spin  fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
</div>
  }

  console.log("length", patientComments.length);

  // if(patientComments?.length === 0) {

  //     return <div>No Comments available</div>
  // }

  if (user?.token === undefined) {
    navigate("/login")
  }

  return (
    <div className="w-full bg-gray-100 p-4 select-none flex font-pop min-h-[calc(100vh-90px)]">
      <div className="w-full bg-white p-4 rounded-md shadow-lg flex flex-col gap-6">
        <div className="w-full flex justify-start items-center gap-6">
          <button className="" onClick={() => navigate("/referrals")}>
            <svg
              class="w-5 h-5 text-black "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 5H1m0 0 4 4M1 5l4-4"
              />
            </svg>
          </button>
          <p className="text-xl font-semibold">Patient Details</p>
        </div>
        <div className="w-full flex justify-start gap-3 items-start">
          <div className="w-[60%] flex flex-col justify-start  items-start gap-4">
            <div className="rounded-md shadow-md border border-gray-300 w-full p-3 flex flex-col gap-4">
              <div className="flex w-full px-3 justify-between items-center">
                <div>
                  <div className="">
                    <p className="text-xs font-medium text-gray-500">
                      Patient Name
                    </p>
                    <p>
                      {patientData?.first_name.length > 0 ||
                      patientData?.last_name.length > 0
                        ? patientData?.first_name + " " + patientData?.last_name
                        : "-"}
                    </p>
                  </div>
                  <div className="pt-5">
                    <p className="text-xs font-medium text-gray-500">
                      Registration Date
                    </p>
                    <p>
                      {patientData.created_at
                        ? patientData?.created_at?.split(" ")?.[0]
                        : "-"}
                    </p>
                  </div>
                  <div className="pt-5">
                    <p className="text-xs font-medium text-gray-500">
                      Assigned Doctor
                    </p>
                    <p>
                      {patientData?.assigned_doctor_first_name.length > 0 ||
                      patientData?.assigned_doctor_last_name.length > 0
                        ? patientData?.assigned_doctor_first_name +
                          " " +
                          patientData?.assigned_doctor_last_name
                        : "-"}
                    </p>
                  </div>
                </div>

                <div>
                  {/* <div>
                    <p className=" text-center  px-2 py-1 rounded-xl bg-blue-500 text-sm font-medium text-white">
                      {patientData?.status}
                    </p>
                  </div> */}
                  <div className="pt-5">
                    <p className="text-xs font-medium text-gray-500">
                      Patient Status
                    </p>
                    <p>{patientData?.status}</p>
                  </div>
                  <div className="pt-5">
                    <p className="text-xs font-medium text-gray-500">
                      Type of Referral
                    </p>
                    <p>
                      {patientData?.type_of_referral.length > 0
                        ? patientData.type_of_referral
                        : "-"}
                    </p>
                  </div>
                  <div className="pt-5">
                    <p className="text-xs font-medium text-gray-500">
                      Referred By
                    </p>
                    <p className="capitalize">
                      {patientData?.created_by_first_name.length > 0 ||
                      patientData?.created_by_last_name.length > 0
                        ? patientData?.created_by_first_name +
                          " " +
                          patientData?.created_by_last_name
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
              {patientData?.type_of_referral === "Surgical Guide Referral" &&
                (patientData?.referral_details?.implant_diameter ||
                  patientData?.referral_details?.implant_site ||
                  patientData?.referral_details?.implant_length ||
                  patientData?.referral_details?.implant_system ||
                  patientData?.referral_details
                    ?.surgical_guide_return_date) && (
                  <div className="px-3">
                    <h2 className=" font-medium border-b-2">
                      Referral Details
                    </h2>
                    <div className="flex w-full flex-col md:flex-row flex-wrap gap-8 gap-y-0">
                      {patientData?.referral_details?.implant_diameter && (
                        <div className="pt-5">
                          <p className="text-xs font-medium text-gray-500">
                            Implant Diameter
                          </p>
                          <p>
                            {patientData?.referral_details?.implant_diameter}
                          </p>
                        </div>
                      )}

                      {patientData?.referral_details?.implant_site && (
                        <div className="pt-5">
                          <p className="text-xs font-medium text-gray-500">
                            Implant Site
                          </p>
                          <p>{patientData?.referral_details?.implant_site}</p>
                        </div>
                      )}

                      {patientData?.referral_details?.implant_length && (
                        <div className="pt-5">
                          <p className="text-xs font-medium text-gray-500">
                            Implant Length
                          </p>
                          <p>{patientData?.referral_details?.implant_length}</p>
                        </div>
                      )}

                      {patientData?.referral_details?.implant_system && (
                        <div className="pt-5">
                          <p className="text-xs font-medium text-gray-500">
                            Implant System
                          </p>
                          <p>{patientData?.referral_details?.implant_system}</p>
                        </div>
                      )}

                      {patientData?.referral_details
                        ?.surgical_guide_return_date && (
                        <div className="pt-5">
                          <p className="text-xs font-medium text-gray-500">
                            Surgical Guide Return Date
                          </p>
                          <p>
                            {
                              patientData?.referral_details
                                ?.surgical_guide_return_date
                            }
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              {patientData?.type_of_referral === "Implant Referral" &&
                (patientData?.referral_details?.teeth_to_replace ||
                  patientData?.referral_details?.bpe_status ||
                  patientData?.referral_details?.is_smoker) && (
                  <div className="px-3">
                    <h2 className=" font-medium border-b-2">
                      Referral Details
                    </h2>
                    <div className="w-full flex md:flex-row flex-col gap-8 gap-y-0 flex-wrap">
                      {patientData?.referral_details?.teeth_to_replace && (
                        <div className="pt-5">
                          <p className="text-xs font-medium text-gray-500">
                            Teeth to Replace
                          </p>
                          <p>
                            {patientData?.referral_details?.teeth_to_replace}
                          </p>
                        </div>
                      )}

                      {patientData?.referral_details?.bpe_status && (
                        <div className="pt-5">
                          <p className="text-xs font-medium text-gray-500">
                            BPE Status
                          </p>
                          <p>{patientData?.referral_details?.bpe_status}</p>
                        </div>
                      )}

                      {patientData?.referral_details?.is_smoker !== null && (
                        <div className="pt-5">
                          <p className="text-xs font-medium text-gray-500">
                            Is Smoker
                          </p>
                          <p>
                            {patientData?.referral_details?.is_smoker === "0"
                              ? "No"
                              : "Yes"}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}

              {patientData?.type_of_referral === "OPG Scan Referral" &&
                patientData?.referral_details?.alerts && (
                  <div className="px-3">
                    <h2 className=" font-medium border-b-2">
                      Referral Details
                    </h2>
                    <div className="w-full flex md:flex-row flex-col gap-10 flex-wrap">
                      <div className="pt-5">
                        <p className="text-xs font-medium text-gray-500">
                          Alerts
                        </p>
                        <p>{patientData?.referral_details?.alerts}</p>
                      </div>
                    </div>
                  </div>
                )}

              {patientData?.type_of_referral === "CBCT Scan Referral" &&
                patientData?.referral_details?.alerts && (
                  <div className="px-3">
                    <h2 className=" font-medium border-b-2">
                      Referral Details
                    </h2>
                    <div className="w-full flex md:flex-row flex-col gap-10 flex-wrap">
                      <div className="pt-5">
                        <p className="text-xs font-medium text-gray-500">
                          Alerts
                        </p>
                        <p>{patientData?.referral_details?.alerts}</p>
                      </div>
                    </div>
                  </div>
                )}

              {/* <div>
                  <p className="text-xs font-medium text-gray-500">
                    Surgical Guide Return Date
                  </p>
                  <p>{patientData?.surgical_guide_return_date}</p>
                </div> */}
              {/* <div className="w-full flex gap-4 justify-between items-center">
                  <div className="p-3 shadow-lg bg-[#415da1] rounded-md flex flex-col gap-3 items-center justify-between w-[22%] h-[90px] text-center">
                    <p className="text-xs font-medium text-[#e9f0ff]">
                      Implant Site
                    </p>
                    <p className="text-white font-medium">
                      {patientData?.implant_site}
                    </p>
                  </div>
                  <div className="p-3 shadow-lg bg-[#415da1] rounded-md flex flex-col gap-3 items-center justify-between w-[22%] h-[90px] text-center">
                    <p className="text-xs font-medium text-[#e9f0ff]">
                      Implant System
                    </p>
                    <p className="text-white font-medium">
                      {patientData?.implant_system}
                    </p>
                  </div>
                  <div className="p-3 shadow-lg bg-[#415da1] rounded-md flex flex-col gap-3 items-center justify-between w-[22%] h-[90px] text-center">
                    <p className="text-xs font-medium text-[#e9f0ff]">
                      Implant Diameter
                    </p>
                    <p className="text-white font-medium">
                      {patientData?.implant_diameter}
                    </p>
                  </div>
                  <div className="p-3 shadow-lg bg-[#415da1] rounded-md flex flex-col gap-3 items-center justify-between w-[22%] h-[90px] text-center">
                    <p className="text-xs font-medium text-[#e9f0ff]">
                      Implant Length
                    </p>
                    <p className="text-white font-medium">
                      {patientData?.implant_length}
                    </p>
                  </div>
                </div> */}
            </div>
            <CommentsSection id={id} />
          </div>
          <div className="w-[40%] min-w-[280px] rounded-md shadow-md border border-gray-300 p-3 flex flex-col gap-4">
            <FileUploads
              patient_id={id}
              refreshPatientDetails={fetchPatientDetails}
              // patient_code={patientData?.patient_code}
              type_of_referral={patientData?.referral_details?.referral_type}
              currentStatus={patientData?.status}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;

// <div className='m-12'>
//     <div className=' rounded-xl p-3 px-5 '>

//         <div className='flex justify-between items-center'>
//             <div>
//             <p className='bg-green-600 w-max rounded-xl p-1 px-2 text-xs text-white'>{patientData.patient_status}</p>
//             <h1 className='text-3xl font-semibold'>{patientData.patient_name}</h1>
//             <h3 className='text-lg font-medium text-neutral-500'>{patientData.patient_code}</h3>
//             </div>
//             <div>
//             <h1 className='text-base font-medium text-neutral-500'>Doctor: {patientData.assigned_doctor.first_name} {patientData.assigned_doctor.last_name}</h1>
//             <h1 className='text-base font-medium text-neutral-500'>Created By: {patientData.created_by.first_name} {patientData.created_by.last_name}</h1>
//             </div>
//         </div>
//         <hr className='bg-slate-300 h-[1.3px]'/>
//         <div className='flex mt-3'>
//             <div className='basis-8/12 border-r-[1.3px] p-2 border-slate-300'>
//                 <div className='flex items-center gap-5'>
//             <div className='flex gap-4 p-5'>
//             <div>
//                 <p className='text-lg py-2 font-semibold'>implant_diameter:</p>
//                 <p className='text-lg py-2 font-semibold'>implant_length: </p>
//                 <p className='text-lg py-2 font-semibold'>implant_site: </p>
//                 <p className='text-lg py-2 font-semibold'>implant_system:</p>
//             </div>
//             <div>
//                 <p className='text-lg py-2'> {patientData.implant_diameter}</p>
//                 <p className='text-lg py-2'> {patientData.implant_length}</p>
//                 <p className='text-lg py-2'> {patientData.implant_site}</p>
//                 <p className='text-lg py-2'> {patientData.implant_system}</p>
//             </div>
//             </div>
//             <div>
//             <div className='flex gap-4 p-5'>
//             <div>
//                 <p className='text-lg py-2 font-semibold'>Gender:</p>
//                 <p className='text-lg py-2 font-semibold'>Practice name (assigned doctor): </p>
//                 <p className='text-lg py-2 font-semibold'>Practice name (created by): </p>
//                 <p className='text-lg py-2 font-semibold'>surgical_guide_return_date:</p>
//             </div>
//             <div>
//                 <p className='text-lg py-2'> Male</p>
//                 <p className='text-lg py-2'> {patientData.assigned_doctor.practice_name}</p>
//                 <p className='text-lg py-2'> {patientData.created_by.practice_name}</p>
//                 <p className='text-lg py-2'> {patientData.surgical_guide_return_date}</p>
//             </div>
//             </div>
//             </div>
//             </div>
//         <h1 className='text-2xl font-semibold bg-blue-300 text-white p-2'>Comments</h1>

//                 {
//                     patientComments.map((patientComment, index) => (
//                         <div  className='flex my-3 items-center align-middle gap-2' key={index}>
//                             <div>
//                                 <div className='bg-slate-100 rounded-full p-2 text-2xl'>{patientComment.created_by.first_name.charAt(0)}{patientComment.created_by.last_name.charAt(0)}</div>
//                             </div>
//                         <div>
//                         <p className='px-2'>{patientComment.comment} </p>
//                         <div className='flex justify-between'>
//                             <p className='px-2 text-sm text-neutral-500'>{patientComment.created_by.first_name} {patientComment.created_by.last_name}</p><span className='text-sm text-neutral-500'>{patientComment.created_at}</span>
//                         </div>
//                         </div>
//                         </div>
//                     ))
//                 }
//             </div>
//             <div className='basis-1/3'>
//                 <h1 className='text-center p-2'>File Upload</h1>
//             </div>
//         </div>

//     </div>

//     <div>

//     </div>
// </div>
