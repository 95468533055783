import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import CheckoutForm from "../components/Checkout";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";

const GenerateTables = ({ data }) => {
  // Create a set of unique day values
  // const uniqueDays = [...new Set(data?.map((item) => item?.day))];
  const tables = [];

  const convertTo12HourFormat = (time24) => {
    let [hours, minutes] = time24.split(':');
    hours = parseInt(hours);
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${period}`;
  };

  Object.keys(data).forEach((day) => {
    // const dayData = data?.filter((item) => item?.day === day);
    const dayData = data[day]

    const table = (
      <div
        key={day}
        className="lg:basis-[49%] items-start gap-3 flex flex-col break-inside-avoid"
      >
        <p className="font-medium">Day {day}</p>
        <table className="rounded-md w-[100%] table-fixed" border={1}>
          <thead className="">
            <tr className="">
              <th className="w-[200px] text-center border border-gray-300 px-2 py-2 text-sm">
                Time
              </th>
              <th className="w-[500px] text-center border border-gray-300 px-2 py-2 text-sm">
                Title
              </th>
            </tr>
          </thead>
          <tbody className="w-full">
            {dayData.map((item, index) => (
              <tr className="" key={index}>
                <td className="items-center align-middle place-content-center self-center text-[13px] text-center border border-gray-300 py-2 px-2">{`${convertTo12HourFormat(item?.time_from)} - ${convertTo12HourFormat(item?.time_to)}`}</td>
                <td className="text-[13px] text-center border border-gray-300 py-2 px-2 whitespace-pre-wrap" style={{ wordWrap: "break-word" }}>
                  {item?.title}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

    tables.push(table);
  });

  return tables;
};

const EventDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { eventId } = useParams();
  const [eventData, setEventData] = useState(location?.state);
  const user = JSON.parse(localStorage.getItem("userData"));
  const [eventExpired, setEventExpired] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [bookedEventIds, setBookedEvents] = useState([]);
  const [loading, setLoading] = useState(false)

  const pdfRef = useRef();
  const reqDateFormat = (date) => {
    const parts = date?.split("-");
    if (parts?.length === 3) {
      // Ensure there are three parts (year, month, and day)
      const year = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1; // Subtract 1 from the month as it is 0-based
      const day = parseInt(parts[2]);

      const originalDate = new Date(year, month, day);

      // Format the date as desired
      const dayNumber = originalDate.getDate();
      const monthName = new Intl.DateTimeFormat("en-US", {
        month: "short",
      }).format(originalDate);
      const yearNumber = originalDate.getFullYear();

      const formattedDate = `${dayNumber} ${monthName} ${yearNumber}`;

      return formattedDate;
    } else {
      console.error("Invalid date format.");
      return "Invalid Date"; // You can return an error message or handle it differently
    }
  };

  function hasCrossedDate(targetDateStr) {
    // Convert the target date string to a Date object
    const targetDate = new Date(targetDateStr);

    // Get the current date
    const currentDate = new Date();

    // Compare the two dates
    return currentDate > targetDate;
  }

  const fetchEventDetails = () => {
    setLoading(true) 
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/get-event-details?event_id=${eventId}`
      )
      .then((response) => {
        setLoading(false)
        setEventData(response?.data?.data?.data);
      })
      .catch((error) => {
        setLoading(false)
        console.log("Error while fetching event by id: ", error);
        alert("Error while fetching event. Please try again.");
        navigate("/events");
      });
  };

  const fetchBookedEvents = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/myBookings`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log("response from fetchbookings: ", response?.data);
        const bookedIDs = response?.data?.data?.response.map(
          (bookedEvent) => bookedEvent.id
        );
        setBookedEvents(bookedIDs);
      })
      .catch((error) => {
        console.log("error while fetchBookings: ", error);
      });
  };

  const bookEvent = (event_id) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/book-event`;
    const payload = {
      event_id: event_id,
      first_name: user?.first_name,
      last_name: user?.last_name,
      email_id: user?.email_id,
      is_paid: 1, //payment success static
    };
    axios
      .post(url, payload)
      .then((response) => {
        console.log("response from booking", response?.data?.data);
        if (response?.data?.data?.status === 200) {
          navigate("/success");
        }
      })
      .catch((error) => {
        console.log("Error while booking: ", error);
        alert("Error while booking. Please try again");
      });
  };

  const checkForBooking = () => {
    // if (user?.token === undefined) {
    //   sessionStorage?.setItem("event_url", window?.location?.href)
    //   alert("You're not logged in. Please login to continue")
    //   navigate("/login")
    // } else {
    //   setShowPayment(true)
    // }
    setShowPayment(true);
  };
  useEffect(() => {
    if (!eventData) {
      fetchEventDetails();
    }
    if (user?.token) {
      fetchBookedEvents();
    }
  }, []);

  console.log("eveentdetails", eventData);

  useEffect(() => {
    let result = hasCrossedDate(eventData?.event_end_date);
    setEventExpired(result);
  }, []);

  const downloadPDF = useReactToPrint({
    content : ()=> pdfRef.current,
    documentTitle:`${eventData?.event_name}.pdf`,
    pageStyle : `
      @page {
        margin: 15px 25px;
      }
    `
  })

  // const downloadPDF = () => {
  //   const input = pdfRef.current;
  //   const marginLeft = 5;
  //   const marginRight = 5;
  //   const marginTop = 10;
  //   const marginBottom = 10;
  //   html2canvas(input, {useCORS:true}).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4", true);
  //     const pdfWidth =
  //       pdf.internal.pageSize.getWidth() - marginLeft - marginRight;
  //     const pdfHeight =
  //       pdf.internal.pageSize.getHeight() - marginTop - marginBottom;
  //     const imageWidth = canvas.width;
  //     const imageHeight = canvas.height;
  //     const ratio = Math.min(pdfWidth / imageWidth, pdfHeight / imageHeight);
  //     const imgx = marginLeft;
  //     const imgy = marginTop;
  //     pdf.addImage(
  //       imgData,
  //       "PNG",
  //       imgx,
  //       imgy,
  //       imageWidth * ratio,
  //       imageHeight * ratio
  //     );
  //     pdf.save(`${eventData.event_name}.pdf`);
  //   });
  // };

  return (
    <div className="w-full  p-4 select-none flex font-pop">
      <div className="w-full p-4 rounded-md flex flex-col gap-6">
        <div className="flex gap-4 gap-y-4 sm:flex-row flex-col justify-between w-full md:items-center">
          <button
            className="  justify-center items-center text-xs sm:text-sm cursor-pointer  flex p-2 px-4 font-medium border-secondary border rounded-md text-secondary"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            onClick={downloadPDF}
            className="text-xs sm:text-sm bg-secondary font-medium w-fit text-white py-2 px-3 rounded-md"
          >
            Download as PDF
          </button>
        </div>
        {loading &&  // loading
          <div className='flex justify-center w-full mt-24'>
             <svg aria-hidden="true" className="inline w-7 h-7 text-gray-200 animate-spin  fill-blue-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
               <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
             </svg>
          </div>
        }
        {/* loading and <></> for left contact mail */}
        {!loading && <>
        <div ref={pdfRef} className="">
          <div className="title-container">
            <div className="flex gap-5 w-full">
              <div className="basis-[50%]">
                <p className="sm:text-xl text-lg font-medium capitalize text-primary">
                  {eventData?.event_name}
                </p>
            <div className=" w-full flex gap-[2%] mt-1">
              {eventData?.images?.Data.slice(0,2)?.map((image, index) => (
                <img
                  key={index + image.name}
                  src={image.url}
                  alt="course_img"
                  className={`${eventData?.images?.Data?.slice(0,2).length===1? 'w-full max-h-[300px]' : 'w-[49%] max-h-[200px]'} object-cover rounded-md`}
                />
              ))}
            </div>
            <div className=" w-full flex gap-[2%] mt-[2%]">
              {eventData?.images?.Data?.slice(2)?.map((image, index) => (
                <img
                  key={index + image.name}
                  src={image.url}
                  alt="course_img"
                  className={`${eventData?.images?.Data?.slice(2).length===1? 'w-full max-h-[250px]' : 'w-[49%] max-h-[200px]'} object-cover rounded-md`}
                />
              ))}
            </div>
              </div>
              <div className="basis-[50%] border rounded-md border-gray-300">
                <div className="p-3 flex flex-col gap-5">
                {/* <p className="text-sm">
                  Speakers:
                
                     {eventData?.speaker_details?.map(speaker=> 
                    <p className="font-medium text-primary mb-2 last:mb-0">
                      {speaker?.name}
                    </p>
                    )}
                  

                </p> */}
                <div>
                  <p className="text-sm mb-1">Speakers:</p>
                  {eventData?.speaker_details?.map(speaker=>
                    <p key={speaker.id} className="font-medium text-primary text-sm mb-1 last:mb-0">
                      {speaker?.name}
                    </p>
                  )}
                </div>
                <div className="flex items-center">
                <p className="text-sm">
                  Course Fee:  </p>
                  <span className="font-medium ml-1 flex items-center text-primary">
                  <svg class="w-4 h-4 text-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 10h9.2M6 14h9.2M18 5a6 6 0 0 0-3.4-1C11 4 7.8 7.6 7.8 12s3 8 6.8 8a6 6 0 0 0 3.4-1"/>
                  </svg>
                  {eventData?.event_fee}</span>
                  </div>
                  <p className="text-sm">
                  Number Of CPD Hours:
                  <span className="font-medium text-primary ml-1">
                    {eventData?.number_of_cpd_hours}
                  </span>
                </p>
                  {/* <div className="flex gap-5">
                  <p className="text-sm border rounded-md border-gray-300 px-2 py-1">
                    Maximum Seats:
                    <span className="font-medium text-primary ml-1">
                      {eventData?.event_max_seats}
                    </span>
                  </p>
                  <p className="text-sm border rounded-md border-gray-300  px-2 py-1">
                    Available Seats:
                    <span className="font-medium text-primary ml-1">
                      {eventData?.event_available_seats}
                    </span>
                  </p>
                  </div> */}
                  <div className="w-full flex items-center gap-3">
                  <svg
                    className="w-4 h-4 text-primary"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm14-7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4Z" />
                  </svg>
                  <p className="text-sm font-medium">{`${reqDateFormat(
                    eventData?.event_start_date
                  )?.replace(/2023/, "")} - ${reqDateFormat(
                    eventData?.event_end_date
                  )}`}</p>
                </div>
                <div className="w-full flex mt-1 gap-3">
                  <svg
                    className="w-4 h-4 text-primary"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 16 20"
                  >
                    <path d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                  </svg>
                  <p className="text-sm font-medium">{`${eventData?.event_place}, ${eventData?.event_street}, ${eventData?.event_city}, ${eventData?.event_country}`}</p>
                </div>
               
                
                </div>
                {/* <p className="text-gray-600 text-sm pb-5 w-full">
                  About the Speaker: <br />
                  <span className="font-medium">
                    {eventData?.speaker_details?.about}
                  </span>
                </p> */}
            
              </div>
            </div>
          </div>
{/* 
          <div className="flex gap-5 py-10">
                <div className="w-1/3 flex grow flex-col">
                <p className="text-primary font-medium">
                  Aim </p>
                <p className="border grow p-2 rounded-md border-gray-300">{eventData?.aim}</p>
                </div>
                <div className="w-1/3 flex grow flex-col">
                <p className=" text-primary font-medium">
                  Objectives</p>
                <p className="border grow p-2 rounded-md border-gray-300">{eventData?.objectives}</p>
                </div>
                {
                  eventData?.gdc_learning_outcomes && 
                  <div className="w-1/3 flex flex-col">
                  <p className=" text-primary font-medium">
                   GDC Learning Outcomes</p>
                  <p className="border grow p-2 rounded-md border-gray-300"> {eventData?.gdc_learning_outcomes}</p>
                  </div>
                }
                </div> */}

<div className="flex max-md:flex-col gap-5 py-10">
                <div className="md:w-1/3 break-inside-avoid flex grow flex-col">
                <p className="text-primary font-medium">
                  Aim </p>
                <p className="border grow p-2 rounded-md border-gray-300">{eventData?.aim}</p>
                </div>
                <div className="md:w-1/3 break-inside-avoid flex grow flex-col">
                <p className=" text-primary font-medium">
                  Objectives</p>
                <p className="border grow p-2 rounded-md border-gray-300">{eventData?.objectives}</p>
                </div>
                {
                  eventData?.gdc_learning_outcomes &&
                  <div className="md:w-1/3 break-inside-avoid flex flex-col">
                  <p className=" text-primary font-medium">
                   GDC Learning Outcomes</p>
                  <p className="border grow p-2 rounded-md border-gray-300"> {eventData?.gdc_learning_outcomes}</p>
                  </div>
                }
                </div>

          <div className="agenda-container flex flex-col gap-4 items-start justify-center">
          
            <p className="font-medium text-lg w-full break-after-avoid">Agenda</p>
            <div className="flex w-full justify-between flex-wrap gap-y-6">
             
              {eventData?.schedule && 
            <GenerateTables data={eventData?.schedule} />
              }
            
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-2 items-center">
          {!(
            eventExpired ||
            bookedEventIds.includes(Number(eventId)) ||
            eventData?.event_available_seats < 1
          ) && (
            <>
            <button
              className="w-[45%] sm:w-[180px] justify-center items-center text-xs sm:text-sm cursor-pointer  flex p-2 px-4 font-medium bg-secondary rounded-md text-white"
              onClick={() => {
                // bookEvent(eventData?.event_id)
                checkForBooking();
              }}
            >
              Book Now
            </button>
           </>
          )}
            <div className='md:mr-36'>
             <h1 className='text-sm'>For more details <br/> 
             Contact:&nbsp; 
             <span className='text-xs cursor-pointer'>contact_id@example.com</span></h1>
           </div>
        </div>
        </>}
      </div>
      {
        showPayment && 
        <CheckoutForm
        fee={eventData?.event_fee}
        event_id={eventData?.event_id}
        modal={showPayment}
        updateShow={setShowPayment}
      />
      }
      
    </div>
  );
};

export default EventDetails;
