import React, { useState, useEffect } from 'react'
import CreateEvent from './CreateEvent'
import ScheduleForm from './AddSchedule'
import { useNavigate } from 'react-router-dom';

const CreateCourse = () => {
    const [currentStep, setCurrentStep] = useState(1)
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('userData'))
    const initialEventInfo = {
      "event_name": '',
        "event_start_date": '',
        "event_end_date": '',
        "event_max_seats": '',
        "event_available_seats": '',
        "event_fee": '',
        "event_place": '',
        "event_street": '',
        "event_city": '',
        "event_state": '',
        "event_country": '',
        "numDays":'',
    }
    const [eventInfo, setEventInfo] = useState(initialEventInfo)
    
    const initialScheduleData = {
      "speakersList" : [{"name":'', "degrees_awarded":'', "about_speaker":''}],
      "schedule": [],
      "photos" : null,
      "aim": '',
      "objective":'',
      "gdcLearningOutcomes":'',
      "cpdHours": '',
      "thumbNails":[]
    }

    
    
    const [scheduleDetails, setScheduleDetails] = useState(initialScheduleData)
    const onNext = ()=> {
        setCurrentStep(2)
    }
    const onBack = ()=> {
        setCurrentStep(1)
    }

  // if(!user?.token){
  //     navigate('/login')
  //   }
  // if(user?.role_id!==1){
  //     navigate('/')
  //   }

    useEffect(() => {
      if (!user?.token) {
          navigate("/login")
      }
      else if(user?.role_id!==1){
        navigate('/')
      }
  },[])

  return (
    <>
    {currentStep===1 
        ? <CreateEvent eventInfo={eventInfo} goToNextPage={onNext} saveEventInfo={setEventInfo}/>
        : <ScheduleForm eventInfo={eventInfo} scheduleData={scheduleDetails} goToPrevPage={onBack} saveScheduleData={setScheduleDetails}/>
    }
    </>
  )
}

export default CreateCourse