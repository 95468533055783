import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateNewModule from "../components/CreateNewModule";
import {toast} from "react-toastify";

// const header = {
//   "ngrok-skip-browser-warning": "skip-browser-warning",
// };

const CreateAssessmentForm = ({updateShow, allmoduleData, updateAssessments}) => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const [eventData, setEventData] = useState([])
  const [selectedEvent, setSelectedEvent] = useState("")
  const [assessmentName, setAssessmentName] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
 
  const fetchEventData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/geteventnames`, {withCredentials:true})
      .then((response) => {
        setEventData(response?.data?.data?.result);
        console.log("response from fetch event", response?.data?.data?.result);
      })
      .catch((error) => {
        console.log(`error while fetching eventData: `, error);
      });
  }

  const createAssessment = () => {

    const matched = allmoduleData.some((item) => (
      item.event_id === Number(selectedEvent) && item.module_name.toLowerCase() === assessmentName.toLowerCase().trim()
    ))

    if(matched){
      toast.error('Assessment name is already taken.')
      return;
    }

    if (selectedEvent?.length > 0) {
      const payload = {
        module_name: assessmentName.trim(),
        id: user?.id,
        event_id: Number(selectedEvent)
      };
      setSubmitting(true)
      const url = `${process.env.REACT_APP_API_URL}api/v1/create-module`;
      axios.post(url, payload, {withCredentials:true})
        .then((response) => {
          const createdModuleId = response?.data?.result?.module?.id
          setSubmitting(false)
          setAssessmentName("");
          setSelectedEvent("");
          navigate(`/newQuestion/${createdModuleId}`)
          console.log("assessment added successfully: ", response?.data);

          updateShow(false);
          // updateAssessments();
        })
        .catch((error) => {
          console.log("Error while login: ", error);
          setSubmitting(false)
        });
    } else {
      // alert("Please add the required values")
    }
    
  };
  useEffect(() => {
    fetchEventData()
  },[])
  return (
    // <div className="w-full flex flex-col gap-4 items-center justify-start text-xs ">
    <div className="w-full flex flex-col gap-6 text-sm ">
      {/* <button
        className="absolute right-3 top-2 border border-primary p-1 rounded-md"
        onClick={() => updateShow(false)}
        >
        <svg className="w-5 h-5 text-primary " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6"/>
        </svg>
      </button> */}
  {/* <p className="font-medium">Create New Assessment</p> */}
      <p className="font-medium text-lg text-center">Create New Assessment</p>
      
      <form
        onSubmit={(e) => {
          e?.preventDefault();
          createAssessment();
        }}
        // className="flex flex-col gap-5"
        className="w-full flex flex-col gap-5"
      >
        <div>
        <label htmlFor="event_selection" className=" font-medium text-primary">Course</label>
          <select
            name="event_selection"
            id="event_selection"
            value={selectedEvent}
         // className="border border-gray-300 p-1 rounded-md"
            className="w-full invalid:text-gray-400 shadow-sm outline-none  border border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2 mt-2"
            required
            onChange={(e) => setSelectedEvent(e?.target?.value)}
          >
            <option value="">Select a Course</option>
            {eventData?.map((event, index) => (
              <option value={event?.id} key={index}
              className="text-black"
              >
                {event?.event_name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full flex flex-col gap-2">
      {/* <label htmlFor="assessment_name">Name of the Assessment</label> */}
          <label htmlFor="assessment_name" className=" font-medium text-primary">Name of the Assessment</label>
      {/* <input required className="border border-gray-300 outline-none p-1 rounded-md" type="text" id="assessment_name" placeholder="Assessment Name" value={assessmentName} onChange={(e)=>setAssessmentName(e?.target?.value)}/> */}
          <input required className=" shadow-sm outline-none  border border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500 block  p-2" type="text" id="assessment_name" placeholder="Assessment Name" value={assessmentName} 
          onChange={(e)=>{
            e.target.value = e.target.value.trimStart()
            setAssessmentName(e?.target?.value)
          }}
          />
        </div>
        <div className="w-full flex justify-center items-center gap-6">
      {/* <button className="px-3 text-white rounded-md font-medium text-xs h-[30px] bg-green-500" onClick={createAssessment}>Create</button> */}
          <button type="submit" className=" text-white rounded-md font-medium bg-secondary py-2 w-[50%]" disabled={submitting}>
          {submitting? 
               <svg aria-hidden="true" className="inline w-5 h-5 text-white animate-spin  fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
               :'Create'}
          </button>
          {/* <button className="px-3 text-white rounded-md font-medium text-xs h-[30px] bg-black" onClick={()=>updateShow(false)}>Cancel</button> */}
          <button type="button" className=" text-secondary  rounded-md font-medium border border-secondary py-2 w-[50%]" 
          onClick={()=>{
            setAssessmentName("");
            setSelectedEvent("");
            updateShow(false)
          }}
          >Cancel</button>
        </div>
      </form>
    </div>
  );
}


const ModuleCard = ({module, fetchAllAssessments})=> {
  const user = JSON.parse(localStorage.getItem("userData"));
  const admin_id = user?.id;
  const navigate = useNavigate()
  const [deletePopup, setDeletePopup] = useState(false)
 
  const deleteModule = (module_id) => {
    const payload = {
      id: admin_id,
      module_id: module_id,
    };
    const url = `${process.env.REACT_APP_API_URL}api/v1/delete-module`;
    console.log('DELETE MODULE-', module_id)
      axios
        .delete(url, { data: payload, withCredentials: true })
        .then((response) => {
          // alert("Module Deleted");
          setDeletePopup(false)
          fetchAllAssessments();
          toast.success("Assessment deleted successfully!", { delay: 500 })
        })
        .catch((error) => {
          // alert(`Please Login Again :  ${error.response.data.error}`)
          console.log(error);
          // navigate("/login")
        });
    // }
  };
 
  return (
          <div className="bg-white shadow-md p-4 rounded-md w-[350px] py-3 flex flex-col justify-between gap-3 items-start">
          {/* <p className="text-base font-medium capitalize"> */}
              <p className="text-base font-medium capitalize text-primary">
                {module?.module_name}
              </p>
              {/*This is for Up Delete <div className="flex items-start justify-between w-full"> */}
              {/* </div> */}
              <p className='text-base'><span>Course name :  </span>{module?.event_name}</p>
              <p className='text-base'><span>Number of questions :  </span>{module?.questions.length}</p>
              <div className="flex justify-between w-full items-center mt-2">
                <button
                  onClick={() => navigate(`/newQuestion/${module.module_id}`, {state:module})}
                  // className="bg-black px-2 py-2 text-white outline-0 border-0 rounded-md text-xs"
                  className="bg-primary px-2 py-2 text-white outline-0 border-0 rounded-md text-xs"
                >
                  Add New Questions
                </button>
                <button
                  onClick={() =>
                    navigate(`/viewAllQuestion/${module.module_id}`)
                  //     navigate(`/viewAllQuestion/${module.module_id}`, {
                  //     state: {
                  //       questions: module.questions,
                  //       refreshAssessment: fetchAllAssessments.toString() // Serialize the function
                  //     }
                  //   })
                  }
                  // className="bg-black px-2 py-2 text-white outline-0 border-0 rounded-md text-xs"
                  className=" px-2 py-2 text-primary outline-0 border border-primary rounded-md text-xs"
                >
                  View & Edit Questions
                </button>
                <button
                  // onClick={() => deleteModule(module.module_id)}
                  onClick={() => setDeletePopup(true)}
                  // className=" text-sm rounded-md p-1  place-self-end bg-gray-700 font-medium"
                  className=" text-sm rounded-md p-1 bg-secondary font-medium"
                >
                  {/* <svg className="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"> */}
                  <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
                  </svg>
                </button>
              </div>
      {/* Confirm delete Popup */}
      <dialog
        open={deletePopup}
        className="p-3 inset-0 fixed z-10 bg-black bg-opacity-30 w-full min-h-screen"
      >
        <div className="w-full min-h-screen p-4 flex justify-center items-center">
          <div className='bg-white flex flex-col p-8 w-full max-w-[400px] rounded-md shadow-md relative'>
              <p className="text-center text-base">Do you want to delete this assessment?</p>
              <div className="self-center space-x-4 mt-12">
                <button className="px-5 py-2 rounded-md text-sm bg-secondary text-white"
                  onClick={()=> deleteModule(module.module_id)}
                >Yes</button>
                <button className="px-5 py-2 rounded-md text-sm border border-secondary text-secondary"
                  onClick={()=> setDeletePopup(false)}
                >No</button>
              </div>
          </div>
        </div>
        </dialog>
            </div>
       
  )
}
 


const AdminDashboard = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userData"));
  const [allmoduleData, setModuleData] = useState([]);
  const [showCreateAssessmentModal, setShowCreateAssessmentModal] = useState(false);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false)
  // const [deletePopup, setDeletePopup] = useState(false)
  
  const admin_id = user?.id;
  // const headers = {
  //   "ngrok-skip-browser-warning": "skip-browser-warning",
  // };

  // const deleteModule = (module_id) => {
  //   const payload = {
  //     id: admin_id,
  //     module_id: module_id,
  //   };
  //   const url = `${process.env.REACT_APP_API_URL}api/v1/delete-module`;
  //   console.log('DELETE MODULE-', module_id)
    
  //   // const confirmDelete = window.confirm(
  //   //   "Do you want to delete this assessment?"
  //   // );
  //   // if (true) {
  //     axios
  //       .delete(url, { data: payload, withCredentials: true })
  //       .then((response) => {
  //         // alert("Module Deleted");
  //         toast.success("Assessment deleted successfully!", { delay: 500 })
  //         fetchAllAssessments();
  //         setDeletePopup(false)
  //       })
  //       .catch((error) => {
  //         // alert(`Please Login Again :  ${error.response.data.error}`)
  //         console.log(error);
  //         // navigate("/login")
  //       });
  //   // }
  // };
  const fetchAllAssessments = () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_API_URL}api/v1/get-all-modules`;
    axios
      .get(url, {withCredentials : true})
      .then((response) => {
        setModuleData(response?.data?.data?.data);
        setMessage("");
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
     
     if (error?.response?.data?.data?.status === 404) {
          setMessage(error.response.data.data.message);
          setModuleData([]);
        }
      });
  };
  useEffect(() => {
    fetchAllAssessments();
  }, []);

  if(!user?.token){
    navigate('/login')
    return;
  }
  if(user?.role_id !== 1 ){
    navigate('/')
    return;
  }

  return (
    <div className="p-3 justify-center items-center font-pop">
      <div className="flex gap-3 p-3">
        <button
          // className="p-2 m-2 bg-blue-700 rounded-md text-red-50 text-sm"
          className="p-2 m-2 bg-secondary rounded-md text-red-50 text-sm"
          onClick={() => setShowCreateAssessmentModal(true)}
        >
          Create Assessment
        </button>
        {/* {showModule && <CreateNewModule setShowmodule={setShowmodule} />} */}
      </div>

      {/* <div className=" flex justify-start items-center w-full p-4 gap-4 flex-wrap tracking-tight text-sm"> */}
      <div className="flex justify-start w-full p-4 gap-4 flex-wrap tracking-tight text-sm">
        {loading ? 
          <div className='flex justify-center w-full mt-20'>
              <svg aria-hidden="true" className="inline w-7 h-7 text-primary animate-spin  fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
          </div>
        :allmoduleData?.length==0?
          <p className="text-lg mx-auto">No Assessments found!</p> // copy below :
          :allmoduleData?.map((module, index) => (
      //       <div
      //         // className="bg-[#EBEBEB]  p-4 rounded-md w-[350px] py-3 flex flex-col justify-start gap-3 items-start"
      //         className="bg-white shadow-md p-4 rounded-md w-[350px] py-3 flex flex-col justify-between gap-3 items-start"
      //         key={index}
      //       >
      //     {/* <p className="text-base font-medium capitalize"> */}
      //         <p className="text-base font-medium capitalize text-primary">
      //           {module?.module_name}
      //         </p>
      //         {/*This is for Up Delete <div className="flex items-start justify-between w-full"> */}
      //         {/* </div> */}
      //         <p className='text-base'><span>Event Name :  </span>{module?.event_name}</p>
      //         <p className='text-base'><span>Number of Questions :  </span>{module?.questions.length}</p>
      //         <div className="flex justify-between w-full items-center mt-2">
      //           <button
      //             onClick={() => navigate(`/newQuestion/${module.module_id}`,{state:module})}
      //             // className="bg-black px-2 py-2 text-white outline-0 border-0 rounded-md text-xs"
      //             className="bg-primary px-2 py-2 text-white outline-0 border-0 rounded-md text-xs"
      //           >
      //             Add New Questions
      //           </button>
      //           <button
      //             onClick={() =>
      //               navigate(`/viewAllQuestion/${module.module_id}`, {
      //                 state: module.questions,
      //               })
      //             }
      //             // className="bg-black px-2 py-2 text-white outline-0 border-0 rounded-md text-xs"
      //             className=" px-2 py-2 text-primary outline-0 border border-primary rounded-md text-xs"
      //           >
      //             View & Edit Questions
      //           </button>
      //           <button
      //             // onClick={() => deleteModule(module.module_id)}
      //             onClick={() => setDeletePopup(true)}
      //             // className=" text-sm rounded-md p-1  place-self-end bg-gray-700 font-medium"
      //             className=" text-sm rounded-md p-1 bg-secondary font-medium"
      //           >
      //             {/* <svg className="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"> */}
      //             <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      //               <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
      //             </svg>
      //           </button>
      //         </div>
      // {/* Confirm delete Popup */}
      // <dialog
      //   open={deletePopup}
      //   className="p-3 inset-0 fixed z-10 bg-black bg-opacity-30 w-full min-h-screen"
      // >
      //   <div className="w-full min-h-screen p-4 flex justify-center items-center">
      //     <div className='bg-white flex flex-col p-8 w-full max-w-[400px] rounded-md shadow-md relative'>
      //         <p className="text-center text-base">Do you want to delete this assessment?</p>
      //         <div className="self-center space-x-4 mt-12">
      //           <button className="px-5 py-2 rounded-md text-sm bg-secondary text-white"
      //             onClick={()=> deleteModule(module.module_id)}
      //           >Yes</button>
      //           <button className="px-5 py-2 rounded-md text-sm border border-secondary text-secondary"
      //             onClick={()=> setDeletePopup(false)}
      //           >No</button>
      //         </div>
      //     </div>
      //   </div>
      //   </dialog>
      //       </div>
      <ModuleCard key={module.module_id} module={module} fetchAllAssessments={fetchAllAssessments}/>
          ))}
        {/* <div> {message && <h1>{message}</h1>}</div> */}
      </div>
      <dialog
        open={showCreateAssessmentModal}
        className="p-3 inset-0 fixed z-10 bg-black bg-opacity-50 w-full min-h-screen"
      >
        <div className="w-full min-h-screen p-4 flex justify-center items-center">
          {/* <div className="md:w-[500px] sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-3"> */}
          <div className='bg-white flex flex-col p-8 w-full max-w-[400px] rounded-md shadow-md relative'>
            {/* <div className="w-full flex justify-end items-center">
              <button
                className="border-2 border-gray-300 p-2 rounded-md"
                onClick={() => setShowCreateAssessmentModal(false)}
              >
                ❌ 
                <svg className="w-5 h-5 text-primary " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6"/>
                </svg>

              </button>
            </div> */}
            <CreateAssessmentForm
              updateShow={setShowCreateAssessmentModal}
              updateAssessments={fetchAllAssessments}
              allmoduleData={allmoduleData}
            />
          </div>
        </div>
      </dialog>
      
    </div>
  );
};

export default AdminDashboard;
