import axios from "axios"
import { useState } from "react"
import {toast} from "react-toastify";

const choices = [
    { value:'choice_1', name:'Option 1'},
    { value:'choice_2', name:'Option 2'},
    { value:'choice_3', name:'Option 3'},
    { value:'choice_4', name:'Option 4'},
]

const EditQuestion = ({ index,data,moduleId ,delelteQ}) => {
    const [isvsible, setVisible] = useState(true)
    const [question, setQuestion] = useState(data)

    const user = JSON.parse(localStorage.getItem("userData"))
    // console.log(data);
    const admin_id = user?.id;
    const token = user?.token;
    // const headers = {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    // };

    const correctOption = Object.keys(question).filter(key=> question[key]===question.correct_answer)[0]
    const [selectedOption, setSelectedOption] = useState(correctOption)

    const addQuestion = (e) => {
        e.preventDefault();

        const allOptions = Object.entries(question).filter(item=> item[0].includes('choice'))
        const uniqueOptions = new Set(allOptions.map(option=> option[1].trim()))
        if(uniqueOptions.size!==allOptions.length){
            toast.warn('All options should be unique')
            return
        }
        setVisible(true)
        const payload = {
            "id": admin_id,
            "module_id": moduleId.id,
            "q_id": question.q_id,
            "question": question.question.trim(),
            "choice_1": question.choice_1.trim(),
            "choice_2": question.choice_2.trim(),
            "choice_3": question.choice_3.trim(),
            "choice_4": question.choice_4.trim(),
            // "correct_answer": question.correct_answer
            "correct_answer": question[selectedOption]
        }
        const url =
          "https://cognismiles-portal-api.scketch.com/api/v1/edit-question";
        axios.put(url, payload,{withCredentials:true})
            .then((response) => {
                toast.success('Question updated')
                console.log(response);
                setQuestion(
                {...question,
                    question:question.question.trim(),
                    choice_1:question.choice_1.trim(),
                    choice_2:question.choice_2.trim(),
                    choice_3:question.choice_3.trim(),
                    choice_4:question.choice_4.trim(),
                    correct_answer:question[selectedOption]
                })
            })
            .catch((error) => {
                console.log("Error while login: ", error)
            })
    }
    
    return (
        <div className="w-full p-5">
{/* All labels are text-primary */}
{/* all fields are 'bg' <-> 'shadow-sm*/}

            <form className="space-y-4" onSubmit={addQuestion}
               >
                <div >
                    <label htmlFor="text" className="block mb-2 text-base font-medium text-primary"> {index+1}. Question Description</label>
                    <textarea type="text" name="question" required id="question" className="shadow-sm border outline-none border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5"
                        disabled={isvsible}
                        value={question.question} 
                        onChange={(e) => {
                            e.target.value = e.target.value.trimStart()
                            setQuestion({ ...question, question: e.target.value })
                        }} 
                        placeholder="Enter Your Question" />

                </div>
                <div className="w-full space-y-4">
                    <div className="flex w-full gap-5">
                    <div className="basis-[50%]">
                        <label htmlFor="text" className="block mb-2 text-sm font-medium text-primary">Option 1 </label>
                        <textarea type="text" name="choice_1" required id="option1" className="shadow-sm border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5"
                            disabled={isvsible} value={question.choice_1} 
                            onChange={(e) => {
                                e.target.value = e.target.value.trimStart()
                                setQuestion({ ...question, choice_1: e.target.value })
                            }} 
                            placeholder="Option1" />
                    </div>
                    <div className="basis-[50%]">
                        <label htmlFor="text" className="block mb-2 text-sm font-medium text-primary">Option 2 </label>
                        <textarea type="text" name="choice_2" required id="option2" className="shadow-sm border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg   block w-full p-2.5"
                            disabled={isvsible} value={question.choice_2} 
                            onChange={(e) => {
                                e.target.value = e.target.value.trimStart()
                                setQuestion({ ...question, choice_2: e.target.value })
                            }} 
                            placeholder="Option2" />
                    </div>
                    </div>
                    <div className="flex w-full gap-5">
                    <div className="basis-[50%]">
                        <label htmlFor="text" className="block mb-2 text-sm font-medium text-primary">Option 3 </label>
                        <textarea type="text" name="choice_3" required id="option3" className="shadow-sm border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                            disabled={isvsible} value={question.choice_3} 
                            onChange={(e) => {
                                e.target.value = e.target.value.trimStart()
                                setQuestion({ ...question, choice_3: e.target.value })
                            }} 
                            placeholder="Option3" />
                    </div>
                    <div className="basis-[50%]">
                        <label htmlFor="text" className="block mb-2 text-sm font-medium text-primary">Option 4 </label>
                        <textarea type="text" name="choice_4" required id="option4" className="shadow-sm border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg block w-full p-2.5  "
                            disabled={isvsible} value={question.choice_4} 
                            onChange={(e) => {
                                e.target.value = e.target.value.trimStart()
                                setQuestion({ ...question, choice_4: e.target.value })
                        }} 
                            placeholder="Option4" />
                    </div>
                    </div>
                </div>



                {/* <div className="w-1/4">
                    <label htmlFor="text" className="block mb-2 text-sm font-medium text-primary">Correct Answer </label>
                    <input type="text" name="correct_answer" required id="creectans" className="shadow-sm border border-gray-300 text-gray-900 outline-none sm:text-sm rounded-lg  block w-full p-2.5"
                        disabled={isvsible} value={question.correct_answer} onChange={(e) => setQuestion({ ...question, correct_answer: e.target.value })} placeholder="Correct Answer" />
                </div> */}

                <div className="flex items-center py-3 gap-3">
                    <label htmlFor="text" className="block text-sm font-medium text-primary">Correct Answer </label>
                    <select className=" p-2 w-[200px] invalid:text-gray-400 text-sm border border-gray-400 rounded-md outline-none"
                        value={selectedOption}
                        required
                        disabled={isvsible}
                        onChange={e=> setSelectedOption(e.target.value)}
                    >  
                        <option value='' disabled>select option</option>
                        {choices?.map(choice=>
                            <option key={choice.value} value={choice.value} className="text-black">{choice.name}</option>
                        )}
                    </select>
                </div>
              
                <div className="flex gap-2">
                    {!isvsible && <button type="submit" className=" text-white bg-secondary font-medium rounded-lg text-sm px-5 py-2.5 ">Submit</button>}
                    {isvsible && <button type="button" onClick={() => setVisible(false)} className=" text-white bg-secondary font-medium rounded-lg text-sm px-5 py-2.5 ">Edit</button>}
                    <span onClick={()=>delelteQ(question.q_id)} className="text-secondary border border-secondary font-medium rounded-lg text-sm px-5 py-2.5  cursor-pointer">Delete</span>
                </div>

            </form>


        </div>
    )
}
export default EditQuestion