import React, { useEffect, useState } from 'react'
import instance from '../axiosconfig'
import axios from 'axios'

const patientStatus = [
    {
        id: 1,
        status: "New Entry"
    },
    {
        id: 2,
        status: "Awaiting Planning"
    },
    {
        id: 3,
        status: "More Info Required"
    },
    {
        id: 4,
        status: "Awaiting Approval"
    },
    {
        id: 5,
        status: "Amend Plan"
    },
    {
        id: 6,
        status: "Approve Plan"
    },
    {
        id: 7,
        status: "Guide Posted"
    },
    {
        id: 8,
        status: "Approved"
    }
    
]


const PatientForm = ({ showModal }) => {

    const user = JSON.parse(localStorage.getItem("userData"))
    const token = user?.token
    // const headers = {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    // };
    const [name, setName] = useState("")
    const [date, setDate] = useState("")
    const [implantSite, setImplantSite] = useState("")
    const [implantSystem, setImplantSystem] = useState("")
    const [implantDiameter, setImplantDiameter] = useState("")
    const [implantLength, setImplantLength] = useState("")
    const [comment, setComment] = useState("")
    const [status, setStatus] = useState()
    const [doctorsList, setDoctorsList] = useState([])
    const [assignedDoc, setAssignedDoc] = useState()


    const resetValues = () => {
        setName("")
        setDate("")
        setImplantSite("")
        setImplantSystem("")
        setImplantDiameter("")
        setImplantLength("")
        setComment("")
        setStatus("")
        setDoctorsList("")
        setAssignedDoc("")
    }

    // const doctorsList = [
    //     {
    //         id: 1,
    //         name: "Aditya Dubagunta"
    //     },
    //     {
    //         id: 2,
    //         name: "Suren Fernando"
    //     },
    //     {
    //         id: 3,
    //         name: "Gautham Pai"
    //     },
    //     {
    //         id: 4,
    //         name: "Anil Gangadharaiah"
    //     },
    // ]

    const fetchAllDoctors = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/v1/getAllDoctorNames`, {withCredentials:true})
        .then((response) => {
            console.log("fetched all doctors: ", response?.data?.data?.data) 
            setDoctorsList(response?.data?.data?.data)
        })
        .catch((error) => {
            console.log("error while fetching docs: ", error)    
        })
    }



    const addNewPatient = () => {
        const endpoint = `${process.env.REACT_APP_API_URL}api/v1/createpatientrecord`;
        console.log(`patient status id:`, status)
        console.log("patient status: ", patientStatus?.find((e)=>e?.id=== Number(status))?.status)
        const payload = {
            "patient_name": name,
            "surgical_guide_return_date": date,
            "implant_site": implantSite,
            "implant_system": implantSystem,
            "implant_diameter": implantDiameter,
            "implant_length": implantLength,
            "patient_status": patientStatus?.find((e)=>e?.id=== Number(status))?.status,
            "assigned_doctor": Number(assignedDoc),
            "created_by": user?.id,
            "status_id": Number(status),
            "comment": comment
        }
        console.log("payload for creating patient: ", payload)
        axios.post(endpoint, payload, {withCredentials:true})
        .then((response) => {
            console.log("added patient. Response:", response?.data)
            resetValues()
            showModal(false)
            
        })
        .catch((error) => {
            console.log("error while adding patient: ", error)    
        })
    }


    useEffect(() => {
       fetchAllDoctors() 
    },[])

  return (
      <div className='w-full flex flex-col justify-center items-start gap-6'>
        <div className='w-full p-2 flex justify-center items-center text-center'>
            <p className='text-lg font-medium'>Patient Form</p>
        </div>
          <form onSubmit={(e) => {
              e?.preventDefault()
              addNewPatient()
          }} className='flex flex-col gap-4 w-full mt-4'>
            <div className='flex md:flex-row w-full flex-col justify-between gap-5 items-center '>
                <div className='w-full'>
                    <label htmlFor="patient_name" className="block mb-2 text-sm font-medium text-gray-900 ">Patient Name</label>
                    <input type="text" name="patient_name" id="patient_name"  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 " placeholder="johnDoe@example.com" value={name} onChange={(e)=>setName(e?.target.value)} required/>
                  </div>  
                <div className='w-full'>
                    <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900 ">Surgical Guide Return Date</label>
                    <input type="date" min={new Date().toISOString().split('T')[0]} name="date" id="date"  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 " placeholder="mm/dd/yyyy" value={date} onChange={(e)=>setDate(e?.target.value)} required/>
              </div>  
              </div>  
              <div className='flex md:flex-row w-full flex-col justify-between gap-5 items-center '>
                <div className='w-full'>
                    <label htmlFor="implant_site" className="block mb-2 text-sm font-medium text-gray-900 ">Implant Site</label>
                    <input type="text" name="implant_site" id="implant_site"  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 " placeholder="upper" value={implantSite} onChange={(e)=>setImplantSite(e?.target?.value)} required/>
                  </div>  
                <div className='w-full'>
                    <label htmlFor="implant_system" className="block mb-2 text-sm font-medium text-gray-900 ">Implant System</label>
                    <input type="text" name="implant_system" id="implant_system"  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 " placeholder="Sweden and Martina" value={implantSystem} onChange={(e)=>setImplantSystem(e?.target.value)} required/>
                </div>  
              </div>  
              <div className='flex md:flex-row w-full flex-col justify-between gap-5 items-center '>
                <div className='w-full'>
                    <label htmlFor="implant_diameter" className="block mb-2 text-sm font-medium text-gray-900 ">Implant Diameter</label>
                    <input type="text" name="implant_diameter" id="implant_diameter"  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 " placeholder="3.50" value={implantDiameter} onChange={(e)=>setImplantDiameter(e?.target.value)} required/>
                  </div>  
                <div className='w-full'>
                    <label htmlFor="implant_length" className="block mb-2 text-sm font-medium text-gray-900 ">Implant Length</label>
                    <input type="text" name="implant_length" id="implant_length"  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 " placeholder="10.00" value={implantLength} onChange={(e)=>setImplantLength(e?.target.value)} required/>
              </div>  
              </div>  
              <div className="w-full">
                  <label htmlFor="comments" className="block mb-2 text-sm font-medium text-gray-900 ">Comments</label>
                  <textarea name="comments" id="comments" className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' value={comment} placeholder='Patient related information' onChange={(e)=>setComment(e?.target?.value)}/>
              </div>
              <div className="w-full">
                  <label htmlFor="patient_status" className="block mb-2 text-sm font-medium text-gray-900 ">Patient Status</label>
                  <select name="patient_status" id="patient_status" className='w-full border border-gray-300 rounded-md text-sm p-2' onChange={(e)=>setStatus(Number(e?.target?.value))} value={status}>
                      
                      <option value="">Select Patient Status</option>
                      {patientStatus?.map((status) => (
                          <option key={status?.id} value={status?.id} className='text-sm '>{status?.status}</option>
                      ))}
                  </select>
              </div>
              <div className="w-full">
                  <label htmlFor="assign_to" className="block mb-2 text-sm font-medium text-gray-900 ">Assing To</label>
                  <select name="assign_to" id="assign_to" className='w-full border border-gray-300 rounded-md text-sm p-2' value={assignedDoc} onChange={(e)=>setAssignedDoc(e?.target?.value)}>
                       <option value="">Select Doctor</option>
                      {doctorsList?.map((dentist) => (
                          <option key={dentist?.id} value={dentist?.id} className='text-sm text-black capitalize'>{dentist?.first_name+" "+dentist?.last_name}</option>
                      ))}
                  </select>
              </div>
              <div className='w-full flex gap-4 justify-center items-center p-2 mt-4'>
                  <button type='submit' className='w-[90px] h-[35px] font-medium text-sm rounded-md bg-green-400 text-white border border-green-400'>Submit</button>
                  <button className='w-[90px] h-[35px] font-medium text-sm rounded-md border border-gray-300' onClick={()=>showModal(false)}>Cancel</button>
            </div> 
        </form>
    </div>
  )
}

export default PatientForm