import React, { useEffect, useState } from 'react';
import { useStripe, useElements, CardElement, Elements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from './../assets/logo-black.png'


const stripePromise = loadStripe('pk_test_51O1i2USFSA3uoRUNZhI0JGHlTZVzmt2iVtJWKMXz4ytFTDw6dMjct4DZ4GhfoXwZrSObxbi67X2o1R07CtHKOiGj00qH9jsxXM');

const Checkout = ({amount, event_id}) => {
     const user = JSON.parse(localStorage.getItem("userData"))
    const stripe = useStripe();
    const elements = useElements();
    const [clientSecret, setClientSecret] = useState('');
    const [paymentComplete, setPaymentComplete] = useState(false);
    const navigate = useNavigate()
    const [cardNumber, setCardNumber] = useState()
    const [expiryDate, setExpiryDate] = useState()
    const [cvv, setCvv] = useState()
    const [isPaying, setIsPaying] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');



    
    const generateTransactionID = () => {
      const characters =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      const idLength = 14;
      let id = "";
      for (let i = 0; i < idLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        id += characters[randomIndex];
      }
      const date = new Date().getTime().toString();
      console.log("generated id: ", id + date);
        const req_value = id + date
        return req_value
    };

    const bookEvent = () => {
        const url = `${process.env.REACT_APP_API_URL}api/v1/book-event`;
        const payload = {
          transaction_id: generateTransactionID(),
          event_id: event_id,
          user_id: user?.id,
          is_paid: 1,
        };
        console.log("payload before booking: ", payload)
        axios.post(url, payload)
        .then((response) => {
            console.log("response from booking", response?.data)    
            if (response?.data?.data?.status === 200) {
                setIsPaying(false);
                navigate("/success")
            }
        })
        .catch((error) => {
            setIsPaying(false);
            console.log("Error while booking: ", error)
            alert("Error while booking. Please try again")
           
        })
    }

    async function getResult(secret) {
        console.log("elements data: ", elements.getElement(CardElement))
        const result = await stripe.confirmCardPayment(secret, {
            payment_method: {
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                    name: `${user?.first_name} ${user?.last_name}`,
                    address: {
                        line1: "123 Street",
                        city: "City",
                        state: "State",
                        postal_code: "123456",
                        country: "IN"
                    }
                },
            },
        });
        if (result?.error) {
            // Handle payment failure
            setIsPaying(false);
            setErrorMessage(result?.error || "Payment failed. Please try again.");
            console.error(result?.error,'errr');
        } else {
            // Payment successful
            bookEvent()
        }  
    }

    const stripeSubmit = async () => {
        setIsPaying(true);

        const payload = {
            amount: amount,
            currency: 'usd',
            description: "Description of the export",
        }
        axios
          .post(
            `${process.env.REACT_APP_API_URL}api/v1/create-payment-intent`,
            payload,
            {withCredentials:true}
          )
          .then((response) => {
            console.log("response from initial post: ", response?.data);
            const data = response?.data;
            setClientSecret(data?.clientSecret);
            getResult(data?.clientSecret);
          })
          .catch((error) => {
            setIsPaying(false);
            console.log("Error from stripesubmit: ", error);
            setErrorMessage(error.message || "Payment failed. Please try again.");
          });     
    }

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "14px",
                "::placeholder": {
                    color: "#aab7c4",
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };
    

    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     // Create a payment intent on your server and get the client_secret
    //     const response = await fetch("https://assessment-portal-api-dev.thesketch.net/api/v1/create-payment-intent",
    //       {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({ amount: 850 }),
    //       }
    //     );
    //     const data = await response.json();
    //     setClientSecret(data.clientSecret);

    //     console.log("elements: ", elements.getElement(CardElement))

    //     // Confirm the card payment
    //     const result = await stripe.confirmCardPayment(data.clientSecret, {
    //         payment_method: {
    //             type: 'card',
    //             card: elements.getElement(CardElement),
    //             billing_details: {
    //                 name: 'John Doe',
    //             },
    //         },
    //     });

    //     if (result?.error) {
    //         // Handle payment failure
    //         console.error(result?.error);
    //     } else {
    //         // Payment successful
    //         navigate("/success");
    //     }
    // };

    return (
<div className='h-full font-pop flex justify-center items-center'>
            <form onSubmit={(e) => {
                e?.preventDefault()
                stripeSubmit()
            }} className='p-4 mt-8 md:w-[650px] sm:w-[400px] w-[280px] flex flex-col gap-8 bg-white'>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
                <div className='w-full flex justify-center mt-5 items-end'>
                <button type="submit" className='bg-secondary shadow-sm text-white w-full py-2 rounded-md text-sm' disabled={!stripe}>
                        {isPaying
                            ? <svg
                                aria-hidden="true"
                                className="inline w-5 h-5 text-gray-100 animate-spin  fill-blue-400"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                    />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                    />
                              </svg>
                            : <p className='flex items-center justify-center'>Pay  <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 10h9.2M6 14h9.2M18 5a6 6 0 0 0-3.4-1C11 4 7.8 7.6 7.8 12s3 8 6.8 8a6 6 0 0 0 3.4-1"/>
                          </svg> {amount}</p>}
                    </button>
                  
                </div>
                { errorMessage && <p className='text-red-500 text-sm text-center'>{errorMessage}</p>}
            </form>
        </div>
    );
};

const CheckoutForm = ({fee, event_id, modal, updateShow, event_name, cpd_hours}) => {
    // const options = {
    //     currency: 'usd'
    // }
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem("userData"))
    // if(!user?.token){
    //     navigate('/login')
    //     return;
    //   }
    //   if(user?.role_id !== 2 ){
    //     navigate('/')
    //     return;
    //   }

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'auto'
        }
    },[])
     
    return (
        <dialog open={modal} className='p-3 inset-0 fixed z-10 bg-black bg-opacity-50 w-full min-h-screen'>
            <div className="w-full min-h-screen p-4 flex justify-center items-center">
                <div className='md:w-[500px] sm:w-[400px] shadow-md w-full bg-white p-4 rounded-md'>
                <div className='w-full flex justify-between items-center border-b border-gray-300 pb-2 '>
                    <p className='font-semibold text-lg text-primary'>Checkout</p>
                    <button type='button' onClick={()=>updateShow(false)} className='mb-1'>
                            <svg className="w-6 h-6 text-white bg-primary rounded-full p-1 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6"/>
                            </svg>
                        </button>
                </div>
                <div className='flex justify-between mt-2'>
                    <div>
                    <p className='mt-2 text-sm'>Course</p>
                    <p className=' text font-medium py-1'>{event_name}</p>
                    <p className=' text-xs text-gray-500'>( {cpd_hours} CPD Hours )</p>
                    </div>
                    <div>
                    <p className='mt-2 text-sm text-end'>Total</p>
                    <p className='text-base text font-medium py-1 '>€ {fee}</p>
                    </div>
                </div>
                {!user?.token
                    ? <div>
                        <p className='text-center  text-xl my-8'>Please Login to book the course</p>
                        <button className='bg-secondary text-white w-[100%] h-[35px] rounded-md text-sm'
                            onClick={()=> navigate('/login')}>Login</button>
                        </div>
                    : <Elements stripe={stripePromise}>
                        <Checkout amount={fee} event_id={event_id} />
                      </Elements>
                }
            </div>
            </div>
            
            
        </dialog>
        
    )
}

export default CheckoutForm;
