import React from 'react'
import { useNavigate } from 'react-router-dom'

const BookingSuccess = () => {
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem("userData"))
    if(!user?.token){
        navigate('/login')
        return;
      }
      if(user?.role_id !== 2 ){
        navigate('/')
        return;
      }
  return (
    <div className='w-full max-h-screen min-h-[800px] bg-gray-100 p-4 select-none flex font-pop items-start justify-center'>
        <div className='sm:w-[80%] w-full bg-white p-4 rounded-md shadow-lg flex flex-col gap-6 justify-center items-center min-h-[300px] mt-[200px]'>
            <div className='w-full flex justify-center items-center flex-col gap-3'>
                <svg className="w-[48px] h-[48px] text-green-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m6.072 10.072 2 2 6-4m3.586 4.314.9-.9a2 2 0 0 0 0-2.828l-.9-.9a2 2 0 0 1-.586-1.414V5.072a2 2 0 0 0-2-2H13.8a2 2 0 0 1-1.414-.586l-.9-.9a2 2 0 0 0-2.828 0l-.9.9a2 2 0 0 1-1.414.586H5.072a2 2 0 0 0-2 2v1.272a2 2 0 0 1-.586 1.414l-.9.9a2 2 0 0 0 0 2.828l.9.9a2 2 0 0 1 .586 1.414v1.272a2 2 0 0 0 2 2h1.272a2 2 0 0 1 1.414.586l.9.9a2 2 0 0 0 2.828 0l.9-.9a2 2 0 0 1 1.414-.586h1.272a2 2 0 0 0 2-2V13.8a2 2 0 0 1 .586-1.414Z"/>
                </svg>
                <p className='text-lg md:text-xl font-medium'>Booking Successful!</p>
                <p className='text-gray-500 text-sm'>An email will be sent to you shortly with the details of event and payment made.</p>  
                <button className='bg-[#141414] text-white text-xs md:text-sm md:w-[120px] w-[90px] p-4 mt-5 rounded-md' onClick={()=>navigate("/events")}>Continue</button>
            </div>    
        </div>
    </div>
  )
}

export default BookingSuccess