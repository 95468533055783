import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams,useNavigate, useLocation } from "react-router-dom";
import {toast} from "react-toastify";

const choices = [
    { value:0, name:'Option 1'},
    { value:1, name:'Option 2'},
    { value:2, name:'Option 3'},
    { value:3, name:'Option 4'},
]

const NewQuestionModule = () => {
    const getModuleId = useParams();
    const navigate = useNavigate();
    const[question,setQuestion] = useState("");
    const [submitting, setSubmitting] = useState(false)
    const [option1, setOption1] = useState("");
    const [option2, setOption2] = useState("");
    const [option3, setOption3] = useState("");
    const [option4, setOption4] = useState("");
    const [correctAns, setAns] = useState("");
    const [correctOption, setCorrectoption] = useState('');
    const [questionArray, setQuestionArray] = useState([])
    const[totalQuestion,setTotal] = useState(null)
    const user = JSON.parse(localStorage.getItem("userData"))
    const admin_id = user?.id;
    const token = user?.token;
    const location = useLocation()
    const existingModule = location?.state
    // const headers = {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    // };
    const addQuestion = () => {
        if(new Set([option1, option2,option3,option4]).size!==4){
            toast.warn('All options should be unique!')
            return
        }
        let questionData ={}
        console.log(option3);
        questionData = {
            "question": question.trim(),
            "choice_1": option1.trim(),
            "choice_2": option2.trim(),
            "choice_3": option3.trim(),
            "choice_4": option4.trim(),
            // "correct_answer": correctAns
            "correct_answer": [option1, option2, option3, option4][correctOption].trim()
        }
        questionArray.push(questionData)
        setTotal(questionArray.length)
        setOption1("")
        setOption2("")
        setOption3("")
        setOption4("")
        setQuestion("");
        setCorrectoption("")
    }

    const submitQuestion=()=>{
        setSubmitting(true);
       const payload=  {
            "id": admin_id,
            "module_id": getModuleId.id,
            "questions": questionArray
        }
        const url = `${process.env.REACT_APP_API_URL}api/v1/create-questions`
        axios.post(url, payload, {withCredentials:true})
            .then((response) => {
                setSubmitting(false);
                console.log(response);
                if (existingModule){
                    toast.success('Assessment updated successfully!')
                }else{
                    toast.success('Assessment created successfully!')

                }
                    navigate('/adminDashboard')
            })
            .catch((error) => {
                setSubmitting(false);
                console.log("Error while login: ", error)
            })

    }

    // if(!user?.token){
    //     navigate('/login')
    //     return;
    //   }
    //   if(user?.role_id !== 1 ){
    //     navigate('/')
    //     return;
    //   }
    useEffect(()=>{
        if (!user?.token) {
            navigate("/login")
        }
        else if(user?.role_id!==1){
          navigate('/')
        }
    },[])
    return (
        <div className="w-full p-5 bg-white  md:mt-0   font-pop">
            <button onClick={()=>navigate(-1)} className="p-2 border border-secondary  text-secondary w-[70px] rounded-md" >Back</button>
            <div className="text-center">

                <h1 className="font-bold text-2xl ">Add Question</h1>
            </div>

            <form className="space-y-4" onSubmit={(e) => {
                        e.preventDefault()
                        addQuestion()
                    }}>
                <div >
                    
                    <label htmlFor="text" 
                    // All lables are 'text-primary'
                    className="block mb-2 text-sm font-medium text-primary ">Question Description</label>
                    <textarea type="text" name="question" required id="question" 
                    //All inputs 'bg' > 'shadow-sm' className="shadow-sm w-full outline-none  border border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500 block  p-2"
                    className="shadow-sm border border-gray-300 text-gray-900 sm:text-sm rounded-lg p-2.5 w-full outline-none"
                      value={question} 
                      onChange={(e) => {
                        e.target.value = e.target.value.trimStart()
                        setQuestion(e?.target.value)}}  
                      placeholder="Enter Your Question"/>
                </div>
                <div className="w-full flex gap-3">

                    <div className="w-1/2">
                        <label htmlFor="text" className="block mb-2 text-sm font-medium text-primary ">Option 1 </label>
                        <textarea type="text" name="option1" required id="option1" className="shadow-sm border border-gray-300 text-gray-900 sm:text-sm rounded-lg p-2.5 w-full outline-none"
                           value={option1} 
                           onChange={(e) => { 
                            e.target.value = e.target.value.trimStart()
                            setOption1(e?.target.value)
                            }}
                             placeholder="Option1" />
                    </div>
                    <div className="w-1/2">
                        <label htmlFor="text" className="block mb-2 text-sm font-medium text-primary ">Option 2 </label>
                        <textarea type="text" name="option2" required id="option2" className="shadow-sm border border-gray-300 text-gray-900 sm:text-sm rounded-lg p-2.5 w-full outline-none"
                           value={option2} 
                           onChange={(e) => { 
                            e.target.value = e.target.value.trimStart()
                            setOption2(e?.target.value)
                            }}
                             placeholder="Option2" />
                    </div>
                </div>

                <div className="w-full flex gap-3">
                    <div className="w-1/2">
                        <label htmlFor="text" className="block mb-2 text-sm font-medium text-primary ">Option 3 </label>
                        <textarea type="text" name="option3" required id="option3" className="shadow-sm border border-gray-300 text-gray-900 sm:text-sm rounded-lg p-2.5 w-full outline-none"
                          value={option3} 
                          onChange={(e) => { 
                            e.target.value = e.target.value.trimStart()
                            setOption3(e?.target.value)
                            }}
                              placeholder="Option3" />
                    </div>
                    <div className="w-1/2">
                        <label htmlFor="text" className="block mb-2 text-sm font-medium text-primary ">Option 4 </label>
                        <textarea type="text" name="option4" required id="option4" className="shadow-sm border border-gray-300 text-gray-900 sm:text-sm rounded-lg p-2.5 w-full outline-none"
                          value={option4} 
                          onChange={(e) => { 
                            e.target.value = e.target.value.trimStart()
                            setOption4(e?.target.value)
                            }}
                              placeholder="Option4" />
                    </div>
                </div>

                {/* <div className="w-1/2">
                    <label htmlFor="text" className="block mb-2 text-sm font-medium text-primary ">Correct Answer </label>
                    <input type="text" name="creectans" required id="creectans" className="shadow-sm border border-gray-300 text-gray-900 sm:text-sm rounded-lg p-2.5 w-full outline-none"
                      value={correctAns} onChange={(e) => setAns(e?.target.value)}  placeholder="Correct Answer" />
                </div> */}
                <div className="w-1/2 flex gap-5 items-center">
                    <label htmlFor="text" className="block mb-2 text-sm font-medium text-primary ">Correct Answer </label>
                    <select className=" p-2 w-[200px] invalid:text-gray-400 text-sm border border-gray-400 rounded-md outline-none"
                        value={correctOption}
                        required
                        onChange={e=> setCorrectoption(e.target.value)}
                        >  
                        <option value='' disabled>select option</option>
                        {choices.map(choice=>
                            <option key={choice.name} value={choice.value} className="text-black">{choice.name}</option>
                        )}
                    </select>
                </div>
                <div className="flex gap-3 items-center pt-3">
                <div>
                    {/* <button type="submit" className=" text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Add in List</button> */}
                    <button type="submit" className="bg-secondary text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center">Add Question</button>

                </div>
                <div className="">
                    {totalQuestion!==null?
                                <button disabled={submitting} onClick= {(e)=>submitQuestion()} type="button" className=" text-white bg-secondary font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                   {submitting? 
               <svg aria-hidden="true" className="inline w-5 h-5 text-white animate-spin  fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
               :`Submit ${questionArray.length} Questions`}
                                    </button>

                    :""    
                }
            </div>
            </div>

            </form>
           
        </div>

    )
}
export default NewQuestionModule;