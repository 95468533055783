import React, { useState } from "react";
import SurgicalGuideFirstStep from "../components/SurgicalGuideFirstStep";
import SurgicalGuideSecondStep from "../components/SurgicalGuideSecondStep";
import SurgicalGuideThirdStep from "../components/SurgicalGuideThirdStep";
import { useNavigate } from "react-router-dom";

const SurgicalReferrals = () => {
  const data = {
    type_of_referral: 0,
    first_name: "",
    last_name: "",
    email_id: "",
    phone_number: "",
    address: "",
    postal_code: "",
    date_of_birth: "",
    reffered_to: "",
    patient_status: "",
    surgical_guide_return_date: "",
    implant_site: "",
    implant_system: "",
    implant_diameter: "",
    implant_length: "",
  };
  const [currentStep, setCurrentStep] = useState(1);
  // const [idFromSection1, setIDFromSection1] = useState(null);
  // const [patientStatus, setPatientStatus] = useState(null);

  const [formData, setFromData] = useState(data);
  const [comment, setComment] = useState("");
  const [fileUploadData, setFileUploadData] = useState({scanDate:'', fileType:'', file:''})
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userData"));

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  }

  const prevStep = ()=> {
    setCurrentStep(currentStep-1)
  }

  if (user?.token === undefined) {
    navigate("/login")
  }
  return (
    <>
      <div className=" my-5 container mx-auto font-pop">
        <h1 className="font-medium text-2xl px-5 sm:px-12 lg:px-20 py-5">
          Surgical Guide Referral
        </h1>
        {/* {currentStep === 1 && <SurgicalGuideFirstStep onNext={nextStep} updatedId={setIDFromSection1} setPatientStatus={setPatientStatus} />}
        {currentStep === 2 && <SurgicalGuideSecondStep onNext={nextStep} idFromSection1={idFromSection1}/>}
        {currentStep === 3 && <SurgicalGuideThirdStep idFromSection1={idFromSection1} patientStatus={patientStatus} />} */}
        {currentStep === 1 && <SurgicalGuideFirstStep onNext={nextStep} savedFormData={formData} saveFormData={setFromData} />}
        {currentStep === 2 && <SurgicalGuideSecondStep onNext={nextStep}  onBack={prevStep}  savedComment={comment} saveComment={setComment}/>}
        {currentStep === 3 && <SurgicalGuideThirdStep formData={formData}  onBack={prevStep}  savedData={fileUploadData} saveData={setFileUploadData}  comment={comment} />}
      </div>
    </>
  );
};

export default SurgicalReferrals;
