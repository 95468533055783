import React from 'react'
import { useNavigate } from 'react-router-dom'

const ThankYou = ({setPopUp, message, navigationPage=null}) => {
  const navigate = useNavigate()
  const closePopUp = ()=>{
    if(navigationPage){
      navigate(navigationPage)
    }
    setPopUp(false)
  }
  return (
    <div className='fixed inset-0 z-50 w-full flex justify-center items-center  bg-black bg-opacity-30 backdrop-blur-sm backdrop-filter'>
  <div className='bg-white flex justify-center items-center text-center px-10 mx-5 py-5 max-w-[350px]  rounded-md shadow-md relative'>
    <div className='flex flex-col items-center'>
    <svg className="w-24 h-24 mb-4 text-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
</svg>

    <h1 className='font-medium text-2xl'>Thank You!</h1>
    <p className='text-base mb-5'>{message}</p>
    <button onClick={closePopUp} className='bg-secondary text-white  font-medium rounded-md shadow-sm w-full py-1'>OK</button>
    </div>
  </div>

</div>
  )
}

export default ThankYou