import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import StudentDashboard from './StudentDashboard'
import AdminDashboard from './AdminDashboard'

const Assessments = () => {
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem("userData"))

    useEffect(() => {
        if (!user?.token) {
            navigate("/login")
        } 
    },[])

    return (
        <>
            {user?.role_id === 2 && <StudentDashboard />}
            {user?.role_id === 1 && <AdminDashboard />}
        </>
    )
       
    
}

export default Assessments