import React, {useState, useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import logo from './../assets/logo-black.png'
import cognismilesLogo from './../assets/cognismiles-logo.png'
import { UserContext } from '../components/InactiviteUserLogout'

const Login = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const {user, setUser} = useContext(UserContext)
    const [password, setPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [isLoginFailed, setIsLoginFailed] = useState(false)
    const [showPassword, setShowPassword] = useState("")
    const handleLogin = () => {
        setIsLoginFailed(false)
        const payload = {
            "email_id": email.trim(),
            "password": password.trim()
        }
        const url = `${process.env.REACT_APP_API_URL}api/v1/login`
        console.log(url);
        axios.post(url, payload, { withCredentials: true})
        .then((response) => {
            // alert("Login Successful") 
            console.log("logni info: ", response?.data)
            setUser(response?.data?.result?.user)
            localStorage?.setItem("userData", JSON.stringify(response?.data?.result?.user))
            let url = sessionStorage.getItem('event_url')
            console.log("url from session: ", url)
            if (url) {
                console.log("url if satisfied")
                window.location.href = url
                sessionStorage?.removeItem("event_url")
            } else {
                navigate("/")
            }
            
        })
        .catch((error) => {
            console.log("Error while login: ", error)    
            setIsLoginFailed(true)
            setErrorMessage(error?.response?.data?.result?.message)
        })
    }

  return (
    <section className="bg-gray-50 ">
  <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 font-pop">
      {/* <p className="flex items-center mb-6 text-2xl font-semibold text-gray-900 ">
          <img className="w-8 h-8 mr-2" src={logo} alt="logo" />
          Cognismiles    
      </p> */}
      <div className="w-full bg-white  rounded-lg shadow-md md:mt-0 sm:max-w-md xl:p-0 ">
      <img className="w-[100px] h-auto mx-auto" src={cognismilesLogo} alt="logo" />
      
          <div className="p-4  space-y-4 md:space-y-6 sm:px-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-2xl ">
                Sign in to your account
            </h1>
                <form className="space-y-4 md:space-y-6" onSubmit={(e) => {
                    e.preventDefault()
                    handleLogin()
                }}>
                  <div>
                      <label htmlFor="email" className="block mb-2  font-medium text-primary ">Your email</label>
                      <input 
                      type="email" 
                      name="email" 
                      id="email"  
                      className="shadow-sm outline-none  border border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" 
                      placeholder="johnDoe@example.com" 
                      value={email} 
                      onChange={(e)=>{
                       
                        setEmail(e?.target.value)
                    }} 
                    // onChange={(e) => {
                    //     e.target.value = e.target.value.trimStart()
                    //     const containsOtherChars = e.target.value.match(/[^0-9a-zA-Z@.]/)
                    //     if(containsOtherChars){
                    //       setEmail(containsOtherChars?.input?.slice(0,containsOtherChars.index))
                    //     }else{
                    //       setEmail(e?.target?.value)
                    //     }
                    //   }}
                      required
                      />
                  </div>
                  <div>
                      <label htmlFor="password" className="block mb-2  font-medium text-primary ">Password</label>
                      <div className='relative'>
                      <input 
                      type={showPassword ? "text" : "password"} 
                      name="password" 
                      id="password" 
                      placeholder="••••••••" 
                      className="shadow-sm outline-none  border border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 " value={password} 
                      onChange={(e)=>{
                        setPassword(e?.target.value.replace(" ",''))
                    }} 
                      required/>
                      {showPassword ? 
                        <svg onClick={() => setShowPassword(!showPassword)} className="w-6 h-6 text-gray-400 absolute top-[20%] right-2 cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-width="2" d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"/>
                            <path stroke="currentColor" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                        </svg> : 
                        <svg onClick={() => setShowPassword(!showPassword)} className="w-6 h-6 text-gray-400 absolute top-[20%] right-2 cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                        </svg>
                        }
                        </div>
                  </div>
                  {isLoginFailed && <p className='text-red-500 text-sm '>{errorMessage}</p>}
                  <div className="flex items-center justify-between">
                      {/* <div className="flex items-start">
                          <div className="flex items-center h-5">
                            <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300" />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="remember" className="text-gray-500 ">Remember me</label>
                          </div>
                      </div> */}
                      <p className="text-sm font-medium text-primary hover:underline  cursor-pointer" onClick={()=>{navigate("/forgot-password")}}>Forgot password?</p>
                  </div>
                  <button type="submit" className="w-full text-white bg-secondary focus:ring-4 focus:outline-none focus:ring-secondary font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Sign in</button>
                  <p className="text-sm items-center text-gray-700">
                      Don’t have an account yet? <span className="font-medium text-primary hover:underline  cursor-pointer" onClick={()=>navigate("/register")}>Sign up</span>
                  </p>
              </form>
          </div>
      </div>
  </div>
</section>
  )
}

export default Login