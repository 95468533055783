import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import {toast} from "react-toastify";

const CreateEvent = ({eventInfo,goToNextPage,saveEventInfo}) => {
    const navigate = useNavigate()
    const [name, setName] = useState(eventInfo?.event_name)
    const [startDate, setStartDate] = useState(eventInfo?.event_start_date)
    const [endDate, setEndDate] = useState(eventInfo?.event_end_date)
    const [fee, setFee] = useState(eventInfo?.event_fee)
    const [maxSeats, setMaxSeats] = useState(eventInfo?.event_max_seats)
    const [availSeats, setAvailSeats] = useState(eventInfo?.event_available_seats)
    const [building, setBuilding] = useState(eventInfo?.event_place)
    const [street, setStreet] = useState(eventInfo?.event_street)
    const [city, setCity] = useState(eventInfo?.event_city)
    const [state, setState] = useState(eventInfo?.event_state)
    const [country, setCountry] = useState(eventInfo?.event_country)
    const [numDays, setNumDays] = useState(eventInfo?.numDays)
    const [eventData, setEventData] = useState()

    const user = JSON.parse(localStorage.getItem("userData"))
    const token = user?.token;
    // const headers = {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    // };

   

    const handleDateChange = (key, e) => {
      const value = e?.target?.value
      if (key === "startDate") {
          setStartDate(value)
          console.log(`start date changed to: ${value}`)
      } else {
          setEndDate(value)
            console.log(`end date changed to: ${value}`)
      }
    }
  
    const findDaysGap = () => {
      const day1 = new Date(startDate)
      const day2 = new Date(endDate)
      const timeDifference = Math.floor((day2 - day1) / (1000 * 60 * 60 * 24)) + 1
      setNumDays(timeDifference)
    }

    // const createNewEvent = () => {
    //     const url = `${process.env.REACT_APP_API_URL}api/v1/create-event`;
    //     const payload =  {
    //         "id": 1,
    //         "event_name": name,
    //         "event_start_date": startDate,
    //         "event_end_date": endDate,
    //         "event_max_seats": Number(maxSeats),
    //         // "event_available_seats": Number(availSeats),
    //         "event_available_seats": Number(maxSeats),
    //         "event_fee": Number(fee),
    //         "event_place": building,
    //         "event_street": street,
    //         "event_city": city,
    //         "event_state": state,
    //         "event_country": country
    //     }
    //     console.log("payload before req: ", payload)
    //     axios.post(url, payload, {withCredentials:true})
    //     .then((response) => {
    //         if (response?.data?.data?.status === 200)
    //         //  alert("Event created successfully")    
    //         console.log("response from event creation: ", response?.data)
    //         // const timeDifference = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24))
    //       // console.log(timeDifference)
          
    //         navigate(`/add_schedule/${response?.data?.data?.event_details?.id}/${numDays}`)
    //     })
    //     .catch((error) => {
    //         console.log("Error while creating event: ", error)    
    //     })
    // }

    const onNext = ()=> {
      const payload =  {
        "id": 1,
        "event_name": name.trim(),
        "event_start_date": startDate,
        "event_end_date": endDate,
        "event_max_seats": Number(maxSeats),
        // "event_available_seats": Number(availSeats),
        "event_available_seats": Number(maxSeats),
        "event_fee": Number(fee),
        "event_place": building.trim(),
        "event_street": street.trim(),
        "event_city": city.trim(),
        "event_state": state.trim(),
        "event_country": country.trim(),
        "numDays": numDays
    }
    // navigate(`/add_schedule/${numDays}`, {state:payload})
    saveEventInfo(payload)
    goToNextPage()
    }

 

  useEffect(() => {
  // if(user?.role_id!==1){
  //    
  //     navigate('/')
  //   }
    findDaysGap()
  }, [endDate, startDate])

  const maxStartDate = new Date()
  maxStartDate.setFullYear(new Date().getFullYear() + 5)

  const minEndDate = new Date(startDate?startDate:null)
  const maxEndDate = new Date(minEndDate)
  maxEndDate.setDate(minEndDate.getDate()+14)

  return (
      <div className='create-event-wrapper w-full flex-col p-4 min-h-screen bg-gray-100 font-pop select-none'>
          <div className='w-full bg-white h-auto rounded-md shadow-lg p-4 flex flex-col justify-start items-center gap-6'>
              <div className='w-full p-4 justify-center flex items-center'>
                  <p className='font-medium text-xl'>Create Course</p>
              </div>
              <form onSubmit={(e) => {
                  e?.preventDefault()
                  // createNewEvent()
                  onNext()
              }} className='w-full gap-4 flex flex-col items-center justify-center'>
                <div className='w-full'>
                    <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">
                      Course Name
                      <span className="text-red-500">*</span>
                      </label>
                    <input type="text" maxLength={50} name="event_name" id="event_name" placeholder="Name of the Course" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={name} 
                    onChange={(e)=> {
                      e.target.value = e.target.value.trimStart();
                      setName(e?.target.value)
                    }} 
                    required/>
                  </div>
                  <div className='w-full flex justify-between gap-5 items-center'>
                        <div className='w-full'>
                            <label htmlFor="event_start" className="block mb-2 text-sm font-medium text-gray-900">
                              Course Start Date
                              <span className="text-red-500">*</span>
                            </label>
                          <input type="date" name="event_start" id="event_start" 
                          min={new Date().toISOString().split('T')[0]} 
                          max={maxStartDate.toISOString().split('T')[0]}
                          placeholder="YYYY/MM/DD" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" 
                          value={startDate} 
                          onChange={(e)=>handleDateChange("startDate", e)} 
                          required/>
                        </div>
                        <div className='w-full'>
                            <label htmlFor="event_end" className="block mb-2 text-sm font-medium text-gray-900 ">
                              Course End Date
                              <span className="text-red-500">*</span>
                              </label>
                            <input placeholder='Please select Start Date first'
                            // onFocus={(e) => (e.target.type = 'date')}
                            type={startDate?'date':'text'} 
                            name="event_end" 
                            id="event_end" 
                            min={minEndDate.toISOString().split('T')[0]}  
                            max={maxEndDate.toISOString().split('T')[0]}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" 
                            value={endDate} 
                            onChange={(e)=>handleDateChange("endDate", e)} 
                            disabled={!startDate} 
                            required/>
                        </div>
                  </div>
                  <div className='w-full'>
                        <label htmlFor="event_fee" className="block mb-2 text-sm font-medium text-gray-900">
                          Course Fee
                          <span className="text-red-500">*</span>
                          </label>
                        <input 
                        type="text" 
                        // inputMode="numeric" 
                        name="event_fee" 
                        id="event_fee" 
                        placeholder="£850" 
                        className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" 
                        value={fee} 
                        // onChange={(e)=>setFee(e?.target.value)}
                        onChange={(e) => {
                          const containsOtherChars = e.target.value.match(/[^0-9.]/)
                          if(containsOtherChars){
                            const otherChar = containsOtherChars[0]
                            e.target.value = e.target.value.replace(otherChar, '')
                            setFee(e.target.value)
                          }else{
                            setFee(e?.target?.value)
                          }
                        }}
                        required/>
                    </div>
                  <div className='w-full flex justify-between gap-5 items-center'>
                    <div className='w-full'>
                        <label htmlFor="event_max_seats" className="block mb-2 text-sm font-medium text-gray-900">
                          Maximum Seats
                          <span className="text-red-500">*</span>
                          </label>
                        <input 
                        type="text" 
                        // inputMode="numeric" 
                        name="event_max_seats" 
                        id="event_max_seats" 
                        placeholder="20" 
                        className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" 
                        value={maxSeats} 
                        // onChange={(e)=>setMaxSeats(e?.target.value)} 
                        onChange={(e) => {
                          const containsOtherChars = e.target.value.match(/[^0-9]/)
                          if(containsOtherChars){
                            const otherChar = containsOtherChars[0]
                            e.target.value = e.target.value.replace(otherChar, '')
                            setMaxSeats(e.target.value)
                          }else{
                            setMaxSeats(e?.target?.value)
                          }
                        }}
                        required/>
                    </div>
                    {/* <div className='w-full'>
                        <label htmlFor="event_avail_seats" className="block mb-2 text-sm font-medium text-gray-900">Available Seats</label>
                          <input type="number" inputMode="numeric" name="event_avail_seats" id="event_avail_seats" placeholder="10" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={availSeats} onChange={(e)=>setAvailSeats(e?.target.value)} required/>
                    </div> */}
                  </div>
                 
                  <div className='w-full'>
                    <label htmlFor="building" className="block mb-2 text-sm font-medium text-gray-900">
                      Building/Flat No
                      <span className="text-red-500">*</span>
                      </label>
                    <input 
                    type="text" 
                    name="building" 
                    id="building" placeholder="Smile Dental Clinic / 201 " className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={building} 
                    onChange={(e)=> {
                      e.target.value = e.target.value.trimStart();
                      setBuilding(e?.target.value)
                      }} required/>
                  </div>
                  <div className='w-full'>
                    <label htmlFor="street" className="block mb-2 text-sm font-medium text-gray-900">
                      Street
                      <span className="text-red-500">*</span>
                      </label>
                    <input type="text" name="street" id="street" placeholder="Unit 5, Longstone Road" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={street} 
                    onChange={(e)=>{
                      e.target.value = e.target.value.trimStart();
                      setStreet(e?.target.value)
                    }} 
                    required/>
                  </div>
                   <div className='w-full'>
                    <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900">
                      City
                      <span className="text-red-500">*</span>
                      </label>
                    <input type="text" name="city" id="city" placeholder="Manchester M22 5LB" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={city} 
                    onChange={(e)=>{
                      e.target.value = e.target.value.trimStart();
                      setCity(e?.target.value)
                    }} 
                    required/>
                  </div>
                  <div className='w-full'>
                    <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900">
                      State
                      <span className="text-red-500">*</span>
                      </label>
                    <input type="text" name="city" id="city" placeholder="Manchester" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={state} 
                    onChange={(e)=>{
                        e.target.value = e.target.value.trimStart();
                        setState(e?.target.value)
                    }} required/>
                  </div>
                   <div className='w-full'>
                    <label htmlFor="country" className="block mb-2 text-sm font-medium text-gray-900">
                      Country
                      <span className="text-red-500">*</span>
                      </label>
                    <input type="text" name="country" id="country" placeholder="United Kingdom" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={country} 
                    onChange={(e)=>{
                      e.target.value = e.target.value.trimStart();
                      setCountry(e?.target.value)
                    }} 
                    required/>
                  </div>
                  <div className="w-full flex gap-5 items-center">
                      <button type='button' className='font-medium outline-none  text-secondary border border-secondary px-8 py-2 rounded-md' onClick={() => navigate(-1)}>Back</button>
                      <button type='submit' className='font-medium outline-none text-white border border-secondary bg-secondary px-8 py-2 rounded-md'>Next</button>
                  </div>
                  
            </form>
          </div>
        
    </div>

                  
                  
  )
}

export default CreateEvent