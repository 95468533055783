import axios from 'axios'
import React, { useState } from 'react'
import ThankYou from '../components/ThankYou'

const Contact = () => {
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [popUp, setPopUp] = useState(false)

  const clearForm = ()=> {
    setEmail('')
    setSubject('')
    setMessage('')
  }

  const handleSubmit = (e)=> {
    e.preventDefault()
    setLoading(true)
    const payload = {
      email,
      subject : subject.trim(),
      message : message.trim()
    }
    console.log(payload)
    const url = `${process.env.REACT_APP_API_URL}api/v1/CONTACT_ENDPOINT`
    axios
    .post(url, payload, {withCredentials:true})
    .then(response=> {
      console.log(response)
      setLoading(false)
      if(response.status===200){
        clearForm()
        setPopUp(true)
      }
    })
    .catch(error=> {
      setLoading(false)
      console.error(error?.message)
    })

  }

  return (
    <section className="bg-white">
  <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
      <h2 className="mb-4 text-4xl tracking-tight font-bold text-center text-gray-900">Contact Us</h2>
      <p className="mb-8 lg:mb-16 font-light text-center text-gray-500  sm:text-xl">Got a technical issue? Need support? Let us know.</p>
      <form onSubmit={handleSubmit} className="space-y-8 font-pop tracking-tight">
          <div>
              <label htmlFor="email" className="block mb-2  font-medium text-primary">Your email
              <span className="text-red-500">*</span>
              </label>

              <input
                value={email}
                onChange={e=> setEmail(e.target.value)}
                // onChange={(e) => {
                //   const containsOtherChars = e.target.value.match(/[^a-zA-Z-@.0-9]/)
                //   if(containsOtherChars){
                //     setEmail(containsOtherChars?.input?.slice(0,containsOtherChars.index))
                //   }else{
                //     setEmail(e.target.value)
                //   }
                // }} 
                type="email" 
                id="email" 
                className="shadow-sm outline-none  border border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" 
                placeholder="name@example.com" 
                required 
                />
          </div>
          <div>
              <label htmlFor="subject" className="block mb-2  font-medium text-primary">Subject
              <span className="text-red-500">*</span>
              </label>
              <input 
                value={subject}
                onChange={(e) => { 
                  e.target.value = e.target.value.trimStart()
                  setSubject(e.target.value)
                }} 
                type="text" 
                id="subject" 
                className="block outline-none p-3 w-full  text-gray-900 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500" 
                placeholder="Let us know how we can help you" 
                required 
                />
          </div>
          <div className="sm:col-span-2">
              <label htmlFor="message" className="block mb-2  font-medium text-primary ">Your message
              </label>
              <textarea 
                value={message}
                onChange={(e) => {
                  e.target.value = e.target.value.trimStart()
                  setMessage(e.target.value)
                }} 
                id="message" 
                rows="6" 
                className="block outline-none p-2.5 w-full text-gray-900 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500" 
                placeholder="Leave a comment..."
                ></textarea>
          </div>
          <button type="submit" className="bg-secondary py-3 px-3 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
            {loading ? 
                <svg aria-hidden="true" className="inline w-5 h-5 text-white animate-spin  fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                :'Send message'}
            </button>
      </form>
  </div>
  {popUp &&
    <ThankYou message={'Your contact information has been received.'} setPopUp={setPopUp}/>
  }
</section>
  )
}

export default Contact