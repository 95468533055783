import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
  } from '@tanstack/react-table'
 

const BasicTable = ({data,columns}) => {

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
      
       
      })

  return (
  <>
   <div className='w-full overflow-x-auto'>
   <table className=' w-full  rounded-md'>
  <thead className="min-w-full text-sm rounded-md">
    {table.getHeaderGroups().map(headerGroup => (
      <tr key={headerGroup.id} className='rounded-md bg-gray-600 text-white text-xs  text-center'>
        {headerGroup.headers.map(header => (
          <th
            key={header.id}
            className='p-2 border border-gray-400 font-medium text-center'
            onClick={header.column.getToggleSortingHandler()}
          >
            {header.isPlaceholder ? null : (
              <div className='flex items-center text-center justify-center'>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
                {
                  { asc: <svg className="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2" d="m5 15 7-7 7 7"/>
                </svg>, desc: <svg className="w-[18px] h-[18px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2" d="m19 9-7 7-7-7"/>
                </svg> }[
                    header.column.getIsSorted() ?? null
                  ]
                }
              </div>
            )}
          </th>
        ))}
      </tr>
    ))}
  </thead>

  <tbody className='bg-white divide-y divide-gray-200'>
    {table.getRowModel().rows.map((row,index) => (
      <tr key={row.id} className={`text-center text-xs ${index % 2 === 0 && "bg-gray-100"} `}>
        {row.getVisibleCells().map(cell => (
          <td key={cell.id} className='p-2 border border-gray-400'>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
</table>

    
      {/* <table className='w3-table-all'>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.isPlaceholder ? null : (
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {
                        { asc: '🔼', desc: '🔽' }[
                          header.column.getIsSorted() ?? null
                        ]
                      }
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        
      </table> */}
      {/* <div>
        <button onClick={() => table.setPageIndex(0)}>First page</button>
        <button
          disabled={!table.getCanPreviousPage()}
          onClick={() => table.previousPage()}
        >
          Previous page
        </button>
        <button
          disabled={!table.getCanNextPage()}
          onClick={() => table.nextPage()}
        >
          Next page
        </button>
        <button onClick={() => table.setPageIndex(table.getPageCount() - 1)}>
          Last page
        </button>
    </div> */}
    </div>
  </>
  )
}

export default BasicTable