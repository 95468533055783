import React, { useEffect, useState } from "react";
import axios from "axios";

const StatusItem = ({
  statusObj,
  columnFilters,
  setColumnFilters,
  activeStatuses,
}) => {
  const handleOnClickStatusItem = () => {
    setColumnFilters((prev) => {
      const stausFilteers = columnFilters.find(
        (filterObj) => filterObj.id === "status"
      )?.value;
      if (!stausFilteers) {
        return prev.concat({
          id: "status",
          value: [statusObj.status],
        });
      }
      return prev.map((filterObj) =>
        filterObj.id === "status"
          ? {
              ...filterObj,
              value: stausFilteers.includes(statusObj.status)
                ? stausFilteers.filter(
                    (aStatus) => aStatus !== statusObj.status
                  )
                : stausFilteers.concat(statusObj.status),
            }
          : filterObj
      );
    });
  };

  return (
    <button
      className={`text-sm text-primary border tracking-wide border-primary rounded-full ${
        activeStatuses.includes(statusObj.status)
          ? "bg-primary text-white"
          : "bg-transparent hover:bg-[#dae1f072]"
      } py-1 px-2`}
      onClick={handleOnClickStatusItem}
    >
      {statusObj.status}
    </button>
  );
};

const StatusFilterButtonAndPopup = (props) => {
  const {
    showFiltersPopup,
    setShowFiltersPopup,
    columnFilters,
    setColumnFilters,
  } = props;
  const [statusArray, setStatusArray] = useState([]);
  // console.log('COLUMN FIlter:::::', columnFilters)
  const activeStatuses =
    columnFilters.find((filterObj) => filterObj.id === "status")?.value || [];

  useEffect(() => {
    fetchPatientStatus();
  }, []);

  const fetchPatientStatus = async () => {
    let allStatusArray = [];
    for (let referralId of [1, 3, 4]) {
      const url = `${process.env.REACT_APP_API_URL}api/v1/getstatuslist/${referralId}`;
      await axios
        .get(url, { withCredentials: true })
        .then((response) => {
          // console.log('response from fetchPatientStatus in Dailog Popup:',(response?.data?.data?.result))
          const refs = response?.data?.data?.result;
          allStatusArray.push(...refs);
          // console.log('allStatusArray:::::',allStatusArray)
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
    allStatusArray.sort((a, b) => a.status.localeCompare(b.status));
    setStatusArray(allStatusArray.filter((status) => status.id !== 9));
  };
  // console.log('statusList :',statusArray)

  return (
    <>
          <div className='flex justify-end'>
      <button className='border rounded-full border-gray-300 py-[10px] px-3 flex gap-2 items-center text-xs'
      // <button className='py-1 px-4 flex items-center gap-2 bg-gray-700 hover:bg-gray-800 rounded-md mb-1 self-end mr-2 '
            onClick={()=> setShowFiltersPopup(true)}>
          <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M18.8 4H5.2a1 1 0 0 0-.7 1.7l5.3 6 .2.7v4.8c0 .2 0 .4.2.4l3 2.3c.3.2.8 0 .8-.4v-7.1c0-.3 0-.5.2-.7l5.3-6a1 1 0 0 0-.7-1.7Z"/>
          </svg>
          <span className='text-gray-500 font-medium'>Patient Status</span>
    </button>
      <dialog
        open={showFiltersPopup}
        className="p-3 inset-0 fixed z-10 bg-black bg-opacity-50 w-full min-h-screen"
      >
        <div className="w-full min-h-screen p-4 flex justify-center items-center">
          <div className="md:w-[600px] sm:w-[400px] w-[280px] bg-white px-4 py-8 relative rounded-md flex flex-col gap-3">
            <p className="text-lg px-4 py-2 font-medium">
              Filter records by Patient Status
            </p>
            <button className='rounded-lg hover:border hover:border-gray-600  absolute right-4 text-gray-600 top-4 w-[26px] h-[30px]'
                  onClick={()=>setShowFiltersPopup(false)}>
              <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 18 6m0 12L6 6"/>
              </svg>
          </button>
            <div className="flex items-center gap-4 flex-wrap px-4 pt-2">
              {statusArray?.map((statusObj) => (
                <StatusItem
                  key={statusObj.id}
                  statusObj={statusObj}
                  columnFilters={columnFilters}
                  setColumnFilters={setColumnFilters}
                  activeStatuses={activeStatuses}
                />
              ))}
            </div>
            <div className=" mt-7 self-center space-x-3 ">
             
              <button
                className="w-[90px] h-[35px] font-medium text-sm text-white rounded-md bg-secondary border border-secondary"
                onClick={() => setShowFiltersPopup(false)}
              >
                OK
              </button>
              <button
                className="w-[90px] h-[35px] font-medium text-sm text-secondary rounded-md border border-secondary"
                onClick={() => setColumnFilters([])}
              >
                Clear All
              </button>
            </div>
          </div>
        </div>
      </dialog>
    </div>
    </>
  );
};

export default StatusFilterButtonAndPopup;
