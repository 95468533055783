import React, { useState, useEffect, useRef, createContext } from "react";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const UserContext = createContext()

const InactivityTimer = ({ children }) => {
const userData = JSON.parse(localStorage.getItem("userData"))
const [user, setUser] = useState(userData)
  const cookies = new Cookies();
  const timerRef = useRef(null);

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keydown",
    "mouseover",
    "mouseleave",
    "mouseup",
    "mouseout",
    "mouseenter",
  ];

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const handleLogout = () => {
    resetTimer();
    cookies.remove("auth_token");
    localStorage.removeItem('userData')
    toast.info("Session timeout!");
    window.location.replace('/login')
  };

  const startLogoutTimer = () => {
    timerRef.current = setTimeout(handleLogout, 15*60*1000); // 15 minutes*
};


const handlePageUnload = ()=> {
  const cookies = new Cookies()
  const expTime = new Date(Date.now() + (30*60*1000))//30 minutes 
  cookies.set('active', true, {expires:expTime})
}

const handlePageLoad = ()=> {
  const active = cookies.get('active')
  if(!active){
    localStorage.removeItem('userData')
    window.location.reload()
  }
}

  useEffect(() => {
    if(user?.token){
    
    const handleUserActivity = () => {
      console.log('Clicked');
      
      resetTimer();
      startLogoutTimer();
    };

    events.forEach((event) =>
      window.addEventListener(event, handleUserActivity)
    );

    startLogoutTimer();

    window.addEventListener('beforeunload', handlePageUnload)
    window.addEventListener('load', handlePageLoad)

    return () => {
      resetTimer();
      events.forEach((event) =>
        window.removeEventListener(event, handleUserActivity)
      );

      window.removeEventListener('beforeunload', handlePageUnload)
      window.removeEventListener('load', handlePageLoad)
    };
}
  }, [user]);

  return (
    <UserContext.Provider value={{user, setUser}}>
      {children}
    </UserContext.Provider>
  )
};

export {InactivityTimer, UserContext};
