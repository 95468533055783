import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import CheckoutForm from '../components/Checkout';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {toast} from "react-toastify";

// const user = JSON.parse(localStorage.getItem("userData"))
let user;
// const header = {
//   "ngrok-skip-browser-warning": "skip-browser-warning",
// };
// const headers = {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${user?.token}`,
// };

const slides = [
    {
        id:1,
        url: "https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
    },
    {
        id:2,
        url: "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    },
]
const images = [
    'https://images.unsplash.com/photo-1606811856475-5e6fcdc6e509?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8ZGVudGFsfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60',
    "https://plus.unsplash.com/premium_photo-1673728802322-294342ae5a07?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZGVudGFsfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    "https://images.unsplash.com/photo-1600170311833-c2cf5280ce49?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZGVudGFsfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    "https://plus.unsplash.com/premium_photo-1675624334898-21a09bdfae83?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGRlbnRhbHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60"
 
]

const EventCard = ({ event, fetchAllEvents, section , bookedEventIds}) => {
    const navigate = useNavigate()
    const [showPayment, setShowPayment] = useState(false)
    const [deletePopup, setDeletePopup] = useState(false)
    const random = Math.floor(Math.random()*4)

      console.log('eventssss', fetchAllEvents);
      console.log('event', event);

      const arrows = event?.images?.Data.length > 0 ? true : false

      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: arrows,
      };

    const reqDateFormat = (date) => {
        const originalDate = new Date(date);
        const day = originalDate.getDate();
        const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(originalDate);
        const year = originalDate.getFullYear();
        const formattedDate = `${day} ${month} ${year}`;
        return formattedDate
    }


    const deleteEvent = (event_id) => {
      // const confirmDelete = window.confirm("Do you really want to delete the event?")
      // if (confirmDelete) {
          const payload = {
              "id":1,
              "event_id":event_id
          }
          console.log("payload before request: ", payload)
          // console.log("headers: ", headers)
          axios
            .delete(
              `${process.env.REACT_APP_API_URL}api/v1/delete-event`,
              { data: payload, withCredentials:true}
            )
            .then((response) => {
              if (response?.data?.data?.status === 200)
              
                // alert("Event Deleted Successfully");
              fetchAllEvents();
              setDeletePopup(false)
              toast.success('Course Deleted Successfully!')
            })
            .catch((error) => {
              // alert("Error while deleting event. Please try again.");
              toast.error('Error while deleting event, Please try again')
              console.log("error: ", error);
            });
      // }
  }

    console.log('ee', event)

    useEffect(() => {
        console.log(`Showpayment value is now changed to: ${showPayment}`)
    }, [showPayment])
    const isBookedEvent = bookedEventIds?.includes(event?.event_id)
    return (
        <>  
            <div key={event?.event_id} className='flex-col flex'>
            <div className='w-[300px] bg-primary rounded-t-md'>
            <p className='font-medium text-white  px-2 py-2 truncate'>{event?.event_name}</p>
              </div>
            <div  className='w-[300px] p-2 shadow-md  pb-4 bg-white gap-3  flex flex-col'>
                <div className="w-full h-[200px] overflow-hidden border rounded-md">
                <Slider {...settings}>
                    {event?.images?.Data?.map((item,index) => (      
                  <img className='object-cover w-full h-[200px] rounded-md' key={item.name+index}  src={item?.url} alt='random'/>
                    ))}
                </Slider>
                    {/* <img src={images[random]} alt={event?.name} className='object-cover w-full h-[200px] md:w-[350px] md:h-[200px] rounded-md'/> */}
                </div>

                <div className="event-info flex flex-col gap-2 font-pop">
                    <div className='flex items-center'><svg class="w-5 h-5 text-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 10h9.2M6 14h9.2M18 5a6 6 0 0 0-3.4-1C11 4 7.8 7.6 7.8 12s3 8 6.8 8a6 6 0 0 0 3.4-1"/>
  </svg><p className='text-primary text-lg font-medium'>{event?.event_fee}</p>
  {(!isBookedEvent && event?.event_available_seats<10 && event?.event_available_seats>0 && section === "available" && user?.role_id === 2)
                  && <p className='ml-auto text-sm text-red-500'>{event?.event_available_seats} seats left</p>
                  }
  </div>
                    <div className='w-full flex gap-2 py-1 items-center'><svg
                    className="w-4 h-4 text-primary"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="currentColor"
                      d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"
                    />
                  </svg><p className='text-sm'>{`${reqDateFormat(event?.event_start_date)?.replace(/2023/, '')} - ${reqDateFormat(event?.event_end_date)}`}</p></div>
                    <div className='w-full py-1 flex gap-2 items-center'><svg
                    className="w-4 h-4 text-primary"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 16 20"
                  >
                    <path d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                  </svg><p className='w-full text-sm truncate'> {`${event?.event_place}, ${event?.event_street}, ${event?.event_city}`}</p></div>
                </div>
                {user?.role_id === 1 ? (
                    section === "available" ? (
                        <div className='flex justify-between gap-2 items-center font-pop'>
                            <button type='button' className='w-[50%] justify-center items-center  flex p-2 px-4 md:text-sm text-xs border-secondary border bg-secondary rounded-md text-white' onClick={()=>navigate(`/edit_event/${event?.event_id}`, {state: event})}>Edit</button>
                            <button type='button' className='w-[50%] justify-center items-center  flex p-2 px-4 md:text-sm text-xs border-secondary border rounded-md text-secondary' onClick={()=> setDeletePopup(true)}>Delete</button>
                        </div>
                    ) : (
                        <div className='flex justify-start gap-2 items-center font-pop'>
                            <button className='w-[50%] justify-center items-center flex p-2 px-4 md:text-sm text-xs bg-secondary rounded-md text-white'onClick={()=> setDeletePopup(true)}>Delete</button>
                        </div> 
                    )
                ) : (
                    section === "available" ? (
                        <div className='flex justify-between gap-2 items-center font-pop'>
                        {isBookedEvent
                            ? <button className='w-[50%] justify-center items-center text-green-600  font-medium flex p-2 px-4 md:text-sm text-xs  rounded-md' disabled >Booked</button>
                            : event?.event_available_seats>0  && <button className='w-[50%] justify-center items-center cursor-pointer bg-secondary flex p-2 px-4 md:text-sm text-xs rounded-md text-white' 
                                                                    onClick={() => {setShowPayment(!showPayment)}}>Book Now
                                                                 </button>
                                
                            }
                        {/* <button className='w-[45%] justify-center items-center cursor-pointer hover:bg-[#e95f3c] flex p-2 px-4 md:text-sm text-xs bg-[#E64D26] rounded-md text-white' 
                            onClick={() => {setShowPayment(!showPayment)}}>Book Now
                        </button>      */}
                        {(event?.event_available_seats<1 && !isBookedEvent) && <p className='text-red-500 text-sm '>No seats available</p>}
                        <button className={`w-[50%] cursor-pointer p-2 px-4 text-xs border border-secondary  rounded-md text-secondary`} onClick={()=>{navigate(`/event_details/${event?.event_id}`,{state: event})}}>More Details</button>
                    </div>
                    ) : (
                        <div className='w-[50%] justify-start flex  items-center font-pop'>
                            <button className='w-full cursor-pointer p-2 px-4 text-xs border border-secondary  rounded-md text-secondary' onClick={()=>{navigate(`/event_details/${event?.event_id}`,{state: event})}}>More Details</button>
                        </div>
                    )    
                
                ) }
            </div>
            </div>
            <dialog
        open={deletePopup}
        className="p-3 inset-0 fixed z-10 bg-black bg-opacity-30 w-full min-h-screen"
      >
        <div className="w-full min-h-screen p-4 flex justify-center items-center">
          <div className='bg-white flex flex-col p-8 w-full max-w-[400px] rounded-md shadow-md relative'>
              <p className="text-center text-base">Do you really want to delete the event?</p>
              <div className="self-center space-x-4 mt-12">
                <button className="px-5 py-2 rounded-md text-sm bg-secondary text-white"
                  onClick={()=> deleteEvent(event?.event_id)}
                >Yes</button>
                <button className="px-5 py-2 rounded-md text-sm border border-secondary text-secondary"
                  onClick={()=> setDeletePopup(false)}
                >No</button>
              </div>
          </div>
        </div>
        </dialog>
              {
                showPayment &&
                <CheckoutForm event_name={event?.event_name} cpd_hours={event?.number_of_cpd_hours} fee={event?.event_fee} event_id={event?.event_id} modal={showPayment} updateShow={setShowPayment} />
              }
            
        </>
        
    )
}

const Events = () => {
    user = JSON.parse(localStorage.getItem("userData"))
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [events, setEvents] = useState([])
    const [expiredEvents, setExpiredEvents] = useState([])
    const [bookedEventIds, setBookedEvents] = useState([])
    
    const fetchAllEvents = () => {
        axios
          .get(`${process.env.REACT_APP_API_URL}api/v1/get-all-events`, {withCredentials:true})
          .then((response) => {
            console.log("events: ", response?.data?.data?.data);
            setEvents(response?.data?.data?.data);
            setLoading(false);
          })
          .catch((error) => {
            if(error?.response?.data?.data?.status === 404){
              setEvents([]) 
            }
            setLoading(false);
            console.log("Error from fetch all events: ", error);
          });
    }

    // const reqDateFormat = (date) => {
    //     const originalDate = new Date(date);
    //     const day = originalDate.getDate();
    //     const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(originalDate);
    //     const year = originalDate.getFullYear();
    //     const formattedDate = `${day} ${month} ${year}`;
    //     return formattedDate
    // }

    // const deleteEvent = (event_id) => {
    //     const confirmDelete = window.confirm("Do you really want to delete the event?")
    //     if (confirmDelete) {
    //         const payload = {
    //             "id":1,
    //             "event_id":event_id
    //         }
    //         console.log("payload before request: ", payload)
    //         console.log("headers: ", headers)
    //         axios
    //           .delete(`${process.env.REACT_APP_API_URL}api/v1/delete-event`, {
    //             data: payload,
    //             headers: headers,
    //           })
    //           .then((response) => {
    //             if (response?.data?.data?.status === 200)
    //               alert("Event Deleted Successfully");
    //             fetchAllEvents();
    //           })
    //           .catch((error) => {
    //             alert("Error while deleting event. Please try again.");
    //             console.log("error: ", error);
    //           });
    //     }
    // }

    const fetchBookedEvents = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/v1/myBookings`, {withCredentials:true})
        .then((response) => {
          console.log("response from fetchbookings: ", response?.data)
          const bookedIDs = response?.data?.data?.response.map(bookedEvent=> bookedEvent.id)
          setBookedEvents(bookedIDs)
        })
        .catch((error) => {
          console.log("Error from fetch all events: ", error);
          setLoading(false)
        });
  
      }

    const getAllExpiredEvents = () => {
        const url = `${process.env.REACT_APP_API_URL}api/v1/get-all-expired-events`;
        axios
          .get(url, {withCredentials:true})
          .then((response) => {
            console.log("response from expired: ", response?.data);
            setExpiredEvents(response?.data?.data?.data);
          })
          .catch((error) => {
            if(error?.response?.data?.data?.status === 404){
              setExpiredEvents([])
            }
            console.log("Error while fetching expired events: ", error);
          });

    }

    // const bookEvent = (event_id) => {
    //     const url = `${process.env.REACT_APP_API_URL}api/v1/book-event`;
    //     const payload = {
    //         "event_id": event_id,
    //         "first_name":user?.first_name,
    //         "last_name":user?.last_name,
    //         "email_id":user?.email_id,
    //         "is_paid":1 
    //     }
    //     console.log("payload before booking: ", payload)
    //     axios.post(url, payload)
    //     .then((response) => {
    //         console.log("response from booking", response?.data)    
    //         if (response?.data?.data?.status === 200) {
    //             navigate("/success")
    //         }
    //     })
    //     .catch((error) => {
    //         console.log("Error while booking: ", error)
    //         alert("Error while booking. Please try again")
    //     })
    // }

    console.log('fetchallevents', events)

    useEffect(() => {
      setLoading(true);
        fetchAllEvents() 
        getAllExpiredEvents()
        if(user?.token){
            fetchBookedEvents()
        }
    },[])
  return (
      <div className="w-full p-4 flex flex-col gap-8 font-pop overflow-x-hidden">
          {user?.role_id === 1 && <div className="w-full p-2">
          <button className='w-[160px] h-[40px] rounded-md cursor-pointer bg-secondary text-white font-medium font-pop border-none outline-none' onClick={()=>navigate('/create_course')}>Add Course</button>
          </div>}
          <div className="flex flex-col w-full gap-6 p-2 font-pop">
              <p className='font-medium text-lg'>Available Courses</p>
            <div className="events-container flex flex-wrap gap-6 gap-y-5">
              {loading?
              <div className='flex justify-center w-full mt-16'>
                <svg aria-hidden="true" className="inline w-7 h-7 text-primary animate-spin  fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
              </div> 
              : events?.length === 0  ?
                <p>No Available course</p>
                :events?.map((event, index) => {
                  return (
                      <EventCard event={event} key={index} fetchAllEvents={fetchAllEvents} section={"available"}  bookedEventIds={bookedEventIds}/>                      
                  )
              })}
          </div>
          </div>
          
          <div className="flex flex-col w-full gap-6 p-2 font-pop">
              <p className='font-medium text-lg'>Past Courses</p>
              <div className='events-container flex flex-wrap gap-y-5 gap-6 w-full'>
                  {!loading && (expiredEvents?.length === 0 ?
                    <p>No past courses</p>
                    :expiredEvents?.map((event, index) => {
                      return (
                      <EventCard event={event} key={index} fetchAllEvents={getAllExpiredEvents} section={"past"} />           
                    // <div key={event?.event_id} className='p-2 shadow-md rounded-md pb-4 bg-white gap-3 flex flex-col'>
                    //       <div className="image-container rounded-md">
                    //           <img src={event?.image} alt={event?.name} className='object-cover w-[280px] h-[200px] md:w-[350px] md:h-[200px] rounded-md'/>
                    //       </div>
                    //       <div className="event-info flex flex-col gap-2 font-pop">
                    //           <p className='md:w-[350px] w-[280px] font-medium text-sm md:text-base truncate'>{event?.event_name}</p>
                    //           <p className='text-[#E64D26] font-medium'>£{event?.event_fee}</p>
                    //           <p className='md:w-[350px] w-[280px] text-sm font-medium'>{`${reqDateFormat(event?.event_start_date)?.replace(/2023/, '')} - ${reqDateFormat(event?.event_end_date)}`}</p>
                    //           <p className='md:w-[350px] w-[280px] text-sm truncate'>{`${event?.event_place}, ${event?.event_street}, ${event?.event_city}`}</p>
                    //       </div>
                    //       {user?.role_id === 1 ? (
                    //         <div className='flex justify-between gap-2 items-center font-pop'>
                    //           <button className='w-[45%] justify-center items-center cursor-pointer hover:bg-[#e95f3c] flex p-2 px-4 md:text-sm text-xs bg-[#E64D26] rounded-md text-white' onClick={()=>navigate(`/edit_event/${event?.event_id}`, {state: event})}>Edit</button>
                    //           <button className='w-[45%] justify-center items-center cursor-pointer hover:bg-gray-900 flex p-2 px-4 md:text-sm text-xs bg-black rounded-md text-white' onClick={()=>{deleteEvent(event?.event_id)}}>Delete</button>
                    //         </div>
                    //       ) : (
                    //         <div className='flex justify-center gap-2 items-center font-pop'>
                    //             <button className='w-[90%] justify-center items-center cursor-pointer hover:bg-gray-900 flex p-2 px-4 md:text-sm text-xs bg-black rounded-md text-white' onClick={()=>{navigate(`/event_details/${event?.event_id}`,{state: event})}}>More Details</button>
                    //         </div>
                    //       ) }
                          
                    // </div>
                  )
              }))}
              </div>
          </div>
    </div>
  )
}

export default Events

const commentObject = {
    "comment_id": 3,
    "patient_id": 3,
    "file_id": null,
    "comment": "Thanks for assigning the patient , I will look into it.",
    "created_by": {
        user_id: 8,
        user_name: "satya",
        practice_name: "Rosebrough Dental Clinic",
    },
    "created_at": "2023-11-09 10:22:04"
}