import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import {toast} from "react-toastify";

const user = JSON.parse(localStorage.getItem("userData"));
const token = user?.token;
// const headers = {
//   "ngrok-skip-browser-warning": "skip-browser-warning",
//   "Content-Type": "application/json",
//   Authorization: `Bearer ${token}`,
// };

// const UserTableRow = ({ user, index, fetchUsers }) => {
//   const [showUserUpdateMenu, setShowUserUpdateMenu] = useState(false);
//   const [userData, setUserData] = useState(user);
//   const navigate = useNavigate();
//   const [firstName, setFirstName] = useState(userData?.first_name);
//   const [lastName, setLastName] = useState(userData?.last_name);
//   const [email, setEmail] = useState(userData?.email_id);
//   const [phone, setPhone] = useState(userData?.phone_number);
//   const [practiceName, setPracticeName] = useState(userData?.practice_name);
//   const [gdc, setGdc] = useState(userData?.gdc_number ?? "");
//   const [practiceAddress, setPracticeAddress] = useState(
//     userData?.practice_address
//   );
//   const [practiceZip, setPracticeZip] = useState(userData?.post_code);
//   const [userRole, setUserRole] = useState(userData?.role_id);

//   const roles = [
//     {
//       role_id: 1,
//       role_name: "Admin",
//     },
//     {
//       role_id: 2,
//       role_name: "User",
//     },
//   ];

//   const handleUserUpdate = (e) => {
//     e?.preventDefault();
//     const payload = {
//       user_id: user?.id,
//       first_name: firstName,
//       last_name: lastName,
//       practice_name: practiceName,
//       phone_number: phone,
//       email_id: email,
//       practice_address: practiceAddress,
//       gdc_number: gdc,
//       post_code: practiceZip,
//       role_id: userRole?.length > 0 ? Number(userRole) : user?.role_id,
//     };
//     console.log("payload for user update: ", payload);
//     axios
//       .put(`${process.env?.REACT_APP_API_URL}api/v1/updateUser`, payload, {withCredentials:true})
//       .then((response) => {
//         console.log("user updated: ", response?.data?.data?.user);
//         setUserData(response?.data?.data?.user);
//         setShowUserUpdateMenu(false);
//       })
//       .catch((error) => {
//         console.log("Error while updating user: ", error);
//       });
//   };

//   const handleUserDelete = () => {
//     const payload = {
//       user_id: user?.id,
//     };
//     const confirmDelete = window.confirm(
//       "Do you really want to delete this user?"
//     );
//     if (confirmDelete) {
//       console.log("delete payload: ", payload)
//       axios
//         .delete(`${process.env?.REACT_APP_API_URL}api/v1/deleteUser`, payload, {withCredentials:true})
//         .then((response) => {
//           console.log("response from delete: ", response?.data)
//           fetchUsers()
//         })
//         .catch((error) => {
//         console.log("error while deleting user: ", error)
//       })
//     }
//   };

//   return (
//     <>
//       <tr
//         className={`text-center   ${index % 2 === 0 && "bg-gray-100"} `}
//         key={user?.id}
//       >
//         <td className="p-2 border border-gray-400 capitalize">
//           {user?.first_name + " " + user?.last_name}
//         </td>
//         <td className="p-2 border border-gray-400 capitalize">
//           {user?.practice_name}
//         </td>
//         <td className="p-2 border border-gray-400">{user?.email_id}</td>
//         <td className="p-2 border border-gray-400">{user?.phone_number}</td>
//         <td className="p-2 border border-gray-400">
//           <div className="w-full justify-center items-center flex gap-6">
//             <button className="" onClick={() => setShowUserUpdateMenu(true)}>
//               <svg
//                 className="w-6 h-6 text-gray-600"
//                 aria-hidden="true"
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="currentColor"
//                 viewBox="0 0 20 16"
//               >
//                 <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z" />
//               </svg>
//             </button>
//             <button className="" onClick={handleUserDelete}>
//               <svg
//                 className="w-6 h-6 text-gray-600"
//                 aria-hidden="true"
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="currentColor"
//                 viewBox="0 0 18 20"
//               >
//                 <path d="M17 4h-4V2a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v2H1a1 1 0 0 0 0 2h1v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1a1 1 0 1 0 0-2ZM7 2h4v2H7V2Zm1 14a1 1 0 1 1-2 0V8a1 1 0 0 1 2 0v8Zm4 0a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0v8Z" />
//               </svg>
//             </button>
//           </div>
//         </td>
//       </tr>
//       <dialog
//         open={showUserUpdateMenu}
//         className="p-3 inset-0 fixed z-10 bg-black bg-opacity-50 w-full min-h-screen"
//       >
//         <div className="w-full min-h-screen p-4 flex justify-center items-center">
//           <div className="md:w-[600px] sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-3">
//             <div className="w-full flex justify-end items-center">
//               <button
//                 className="border-2 border-gray-300 p-2 rounded-md"
//                 onClick={() => setShowUserUpdateMenu(false)}
//               >
//                 ❌
//               </button>
//             </div>
//             <p className="w-full font-medium">User Update Form</p>
//             <form
//               onSubmit={(e) => handleUserUpdate(e)}
//               className="flex flex-col gap-4 w-full mt-4"
//             >
//               <div className="flex md:flex-row w-full flex-col justify-between gap-5 items-center text-xs">
//                 <div className="w-1/2 flex justify-start items-start flex-col">
//                   <label
//                     htmlFor="fName"
//                     className="block mb-2 font-medium text-gray-500"
//                   >
//                     First Name
//                   </label>

//                   <input
//                     value={firstName}
//                     autoComplete="off"
//                     onChange={(e) => setFirstName(e?.target?.value)}
//                     type="text"
//                     name="first_name"
//                     id="fName"
//                     className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
//                     placeholder="John"
//                     required
//                   />
//                 </div>
//                 <div className="w-1/2 flex justify-start items-start flex-col">
//                   <label
//                     htmlFor="lName"
//                     className="block mb-2   font-medium text-gray-500 "
//                   >
//                     Last Name
//                   </label>
//                   <input
//                     value={lastName}
//                     autoComplete="off"
//                     onChange={(e) => setLastName(e?.target?.value)}
//                     type="text"
//                     name="last_name"
//                     id="lName"
//                     className="bg-gray-50 border border-gray-300 text-gray-900 sm:  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
//                     placeholder="Doe"
//                     required
//                   />
//                 </div>
//               </div>
//               <div className="flex md:flex-row w-full flex-col justify-between gap-5 items-center text-xs">
//                 <div className="w-1/2 flex justify-start items-start flex-col">
//                   <label
//                     htmlFor="email"
//                     className="block mb-2   font-medium text-gray-500 "
//                   >
//                     Email
//                   </label>

//                   <input
//                     value={email}
//                     autoComplete="off"
//                     onChange={(e) => setEmail(e?.target?.value)}
//                     type="email"
//                     name="email"
//                     id="email"
//                     className="bg-gray-50 border border-gray-300 text-gray-900 sm:  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
//                     placeholder="John"
//                     required
//                   />
//                 </div>
//                 <div className="w-1/2 flex justify-start items-start flex-col">
//                   <label
//                     htmlFor="phone_number"
//                     className="block mb-2   font-medium text-gray-500 "
//                   >
//                     Phone
//                   </label>
//                   <input
//                     value={phone}
//                     autoComplete="off"
//                     onChange={(e) => setPhone(e?.target?.value)}
//                     type="text"
//                     name="phone_number"
//                     id="phone_number"
//                     className="bg-gray-50 border border-gray-300 text-gray-900 sm:  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
//                     placeholder="Doe"
//                     required
//                   />
//                 </div>
//               </div>
//               <div className="flex md:flex-row w-full flex-col justify-between gap-5 items-center text-xs">
//                 <div className="w-1/2 flex justify-start items-start flex-col">
//                   <label
//                     htmlFor="practiceName"
//                     className="block mb-2   font-medium text-gray-500 "
//                   >
//                     Practice Name
//                   </label>

//                   <input
//                     value={practiceName}
//                     autoComplete="off"
//                     onChange={(e) => setPracticeName(e?.target?.value)}
//                     type="text"
//                     name="practice_name"
//                     id="practiceName"
//                     className="bg-gray-50 border border-gray-300 text-gray-900 sm:  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
//                     placeholder="John"
//                     required
//                   />
//                 </div>
//                 <div className="w-1/2 flex justify-start items-start flex-col">
//                   <label
//                     htmlFor="pPCode"
//                     className="block mb-2   font-medium text-gray-500 "
//                   >
//                     Practice Postal Code
//                   </label>
//                   <input
//                     value={practiceZip}
//                     autoComplete="off"
//                     onChange={(e) => setPracticeZip(e?.target?.value)}
//                     type="text"
//                     name="pp_code"
//                     id="pPCode"
//                     className="bg-gray-50 border border-gray-300 text-gray-900 sm:  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
//                     placeholder="Doe"
//                     required
//                   />
//                 </div>
//               </div>
//               <div className="w-full flex justify-start items-start flex-col text-xs">
//                 <label
//                   htmlFor="pAddress"
//                   className="block mb-2   font-medium text-gray-500 "
//                 >
//                   Practice Address
//                 </label>
//                 <input
//                   value={practiceAddress}
//                   autoComplete="off"
//                   onChange={(e) => setPracticeAddress(e?.target?.value)}
//                   type="text"
//                   name="p_address"
//                   id="pAddress"
//                   className="bg-gray-50 border border-gray-300 text-gray-900 sm:  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
//                   placeholder="Doe"
//                   required
//                 />
//               </div>
//               <div className="flex md:flex-row w-full flex-col justify-between gap-5 items-center text-xs">
//                 <div className="w-1/2 flex justify-start items-start flex-col">
//                   <label
//                     htmlFor="gdc_id"
//                     className="block mb-2   font-medium text-gray-500 "
//                   >
//                     GDC ID
//                   </label>
//                   <input
//                     value={gdc}
//                     autoComplete="off"
//                     onChange={(e) => setGdc(e?.target?.value)}
//                     type="text"
//                     name="gdc_id"
//                     id="gdc_id"
//                     className="bg-gray-50 border border-gray-300 text-gray-900 sm:  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
//                     placeholder="XXXXXXXXXXX"
//                     required
//                   />
//                 </div>
//                 <div className="w-1/2 flex justify-start items-start flex-col">
//                   <label
//                     htmlFor="role_selector"
//                     className="block mb-2   font-medium text-gray-500 "
//                   >
//                     User Role
//                   </label>
//                   <select
//                     name="role_selector"
//                     id="role_selector"
//                     className="w-full border border-gray-300 rounded-md text-xs p-2"
//                     value={userRole}
//                     onChange={(e) => setUserRole(e?.target?.value)}
//                   >
//                     <option value="">Select Role</option>
//                     {roles?.map((role, index) => (
//                       <option
//                         value={role?.role_id}
//                         key={index + role?.role_name + role?.role_id}
//                       >
//                         {role?.role_name}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               </div>
//               <div className="w-full flex gap-4 justify-center items-center">
//                 <button
//                   className="w-[90px] h-[35px] rounded-md outline-none text-xs text-white font-medium bg-green-500"
//                   onClick={() => {}}
//                 >
//                   Update
//                 </button>
//                 <button
//                   className="w-[90px] h-[35px] rounded-md outline-none text-xs text-white font-medium bg-gray-900"
//                   onClick={() => setShowUserUpdateMenu(false)}
//                 >
//                   Cancel
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </dialog>
//     </>
//   );
// };
const EditUserPopup = ({editUser, setEditUser, fetchUsers})=> {
  console.log('Check-Props:',editUser)
  const [userData, setUserData] = useState(editUser.userData);
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(userData?.first_name);
  const [lastName, setLastName] = useState(userData?.last_name);
  const [email, setEmail] = useState(userData?.email_id);
  const [phone, setPhone] = useState(userData?.phone_number);
  const [practiceName, setPracticeName] = useState(userData?.practice_name);
  const [gdc, setGdc] = useState(userData?.gdc_number ?? "");
  const [practiceAddress, setPracticeAddress] = useState(
    userData?.practice_address
  );
  const [practiceZip, setPracticeZip] = useState(userData?.post_code);
  const [userRole, setUserRole] = useState(userData?.role_id);

  const roles = [
    {
      role_id: 1,
      role_name: "Admin",
    },
    {
      role_id: 2,
      role_name: "User",
    },
  ];

  const handleUserUpdate = (e) => {
    e?.preventDefault();
    setSubmitting(true);
    const payload = {
      user_id: userData?.id,
      first_name: firstName,
      last_name: lastName,
      practice_name: practiceName.trim(),
      phone_number: phone,
      email_id: email,
      practice_address: practiceAddress.trim(),
      gdc_number: gdc,
      post_code: practiceZip,
      // role_id: userRole?.length > 0 ? Number(userRole) : user?.role_id,
      role_id: Number(userRole)
    };
    console.log("payload for user update: ", payload);
    axios
      .put(`${process.env?.REACT_APP_API_URL}api/v1/updateUser`, payload, {withCredentials:true})
      .then((response) => {
        setSubmitting(false);
        console.log("user updated: ", response?.data?.data?.user);
        setUserData(response?.data?.data?.user);
        setEditUser({show:false, userData:{}});
        fetchUsers()
        toast.success('User details updated successfully', {delay:500})
      })
      .catch((error) => {
        setSubmitting(false);
        console.log("Error while updating user: ", error);
      });
  };

  return (
    <dialog
        open={true}
        className="p-3 inset-0 fixed z-10 bg-black bg-opacity-50 w-full min-h-screen"
      >
        <div className="w-full min-h-screen p-4 flex justify-center items-center">
          <div className="md:w-[600px] sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-3">
            <div className="w-full flex justify-end items-center">
              <button
                className="border-2 border-gray-300 p-2 rounded-md"
                onClick={() => setEditUser({show:false, userData:{}})}
              >
                ❌
              </button>
            </div>
            <p className="w-full font-medium">User Update Form</p>
            <form
              onSubmit={(e) => handleUserUpdate(e)}
              className="flex flex-col gap-4 w-full mt-4"
            >
              <div className="flex md:flex-row w-full flex-col justify-between gap-5 items-center text-xs">
                <div className="w-1/2 flex justify-start items-start flex-col">
                  <label
                    htmlFor="fName"
                    className="block mb-2 font-medium text-gray-500"
                  >
                    First Name
                  </label>

                  <input
                    value={firstName}
                    // onChange={(e) => setFirstName(e?.target?.value)}
                    onChange={(e) => {
                      const containsOtherChars = e.target.value.match(/[^a-zA-Z]/)
                      if(containsOtherChars){
                          const otherChar = containsOtherChars[0]
                        setFirstName(e.target.value.replace(otherChar,''))
                      }else{
                        setFirstName(e?.target?.value)
                      }
                    }}
                    type="text"
                    name="first_name"
                    id="fName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                    placeholder="John"
                    required
                  />
                </div>
                <div className="w-1/2 flex justify-start items-start flex-col">
                  <label
                    htmlFor="lName"
                    className="block mb-2   font-medium text-gray-500 "
                  >
                    Last Name
                  </label>
                  <input
                    value={lastName}
                    // onChange={(e) => setLastName(e?.target?.value)}
                    onChange={(e) => {
                      const containsOtherChars = e.target.value.match(/[^a-zA-Z]/)
                      if(containsOtherChars){
                          const otherChar = containsOtherChars[0]
                        setLastName(e.target.value.replace(otherChar,''))
                      }else{
                        setLastName(e?.target?.value)
                      }
                    }}
                    type="text"
                    name="last_name"
                    id="lName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                    placeholder="Doe"
                    required
                  />
                </div>
              </div>
              <div className="flex md:flex-row w-full flex-col justify-between gap-5 items-center text-xs">
                <div className="w-1/2 flex justify-start items-start flex-col">
                  <label
                    htmlFor="email"
                    className="block mb-2   font-medium text-gray-500 "
                  >
                    Email
                  </label>

                  <input
                    value={email}
                    onChange={(e) => setEmail(e?.target?.value)}
                    // onChange={(e) => {
                    //   const containsOtherChars = e.target.value.match(/[^0-9a-zA-Z@.]/)
                    //   if(containsOtherChars){
                    //       const otherChar = containsOtherChars[0]
                    //     setEmail(e.target.value.replace(otherChar,''))
                    //   }else{
                    //     setEmail(e?.target?.value)
                    //   }
                    // }}
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                    placeholder="John@example.com"
                    required
                  />
                </div>
                <div className="w-1/2 flex justify-start items-start flex-col">
                  <label
                    htmlFor="phone_number"
                    className="block mb-2   font-medium text-gray-500 "
                  >
                    Phone
                  </label>
                  <input
                    value={phone}
                    // onChange={(e) => setPhone(e?.target?.value)}
                    minLength={10}
                    maxLength={10}
                    onChange={(e) => {
                    const containsOtherChars = e.target.value.match(/[^0-9]/)
                    if(containsOtherChars){
                        const otherChar = containsOtherChars[0]
                      setPhone(e.target.value.replace(otherChar,''))
                    }else{
                      setPhone(e?.target?.value)
                    }
                  }}
                    type="text"
                    name="phone_number"
                    id="phone_number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                    placeholder="Doe"
                    required
                  />
                </div>
              </div>
              <div className="flex md:flex-row w-full flex-col justify-between gap-5 items-center text-xs">
                <div className="w-1/2 flex justify-start items-start flex-col">
                  <label
                    htmlFor="practiceName"
                    className="block mb-2   font-medium text-gray-500 "
                  >
                    Practice Name
                  </label>

                  <input
                    value={practiceName}
                    // onChange={(e) => setPracticeName(e?.target?.value)}
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart();
                      const containsOtherChars = e.target.value.match(/[^a-zA-Z ]/)
                      if(containsOtherChars){
                          const otherChar = containsOtherChars[0]
                        setPracticeName(e.target.value.replace(otherChar,''))
                      }else{
                        setPracticeName(e?.target?.value)
                      }
                    }}
                    type="text"
                    name="practice_name"
                    id="practiceName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                    placeholder="John"
                    required
                  />
                </div>
                <div className="w-1/2 flex justify-start items-start flex-col">
                  <label
                    htmlFor="pPCode"
                    className="block mb-2   font-medium text-gray-500 "
                  >
                    Practice Postal Code
                  </label>
                  <input
                    value={practiceZip}
                    // onChange={(e) => setPracticeZip(e?.target?.value)}
                    minLength={6}
                    maxLength={6}
                    onChange={(e) => {
                    const containsOtherChars = e.target.value.match(/[^0-9]/)
                    if(containsOtherChars){
                        const otherChar = containsOtherChars[0]
                      setPracticeZip(e.target.value.replace(otherChar,''))
                    }else{
                      setPracticeZip(e?.target?.value)
                    }
                  }}
                    type="text"
                    name="pp_code"
                    id="pPCode"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                    placeholder="Doe"
                    required
                  />
                </div>
              </div>
              <div className="w-full flex justify-start items-start flex-col text-xs">
                <label
                  htmlFor="pAddress"
                  className="block mb-2   font-medium text-gray-500 "
                >
                  Practice Address
                </label>
                <input
                  value={practiceAddress}
                  onChange={(e) => {
                    e.target.value = e.target.value.trimStart();
                    setPracticeAddress(e?.target?.value)
                  }}
                  type="text"
                  name="p_address"
                  id="pAddress"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                  placeholder="Practice address"
                  required
                />
              </div>
              <div className="flex md:flex-row w-full flex-col justify-between gap-5 items-center text-xs">
                <div className="w-1/2 flex justify-start items-start flex-col">
                  <label
                    htmlFor="gdc_id"
                    className="block mb-2   font-medium text-gray-500 "
                  >
                    GDC ID
                  </label>
                  <input
                    value={gdc}
                    // onChange={(e) => setGdc(e?.target?.value)}
                    onChange={(e) => {
                      const containsOtherChars = e.target.value.match(/[^0-9]/)
                      if(containsOtherChars){
                          const otherChar = containsOtherChars[0]
                        setGdc(e.target.value.replace(otherChar,''))
                      }else{
                        setGdc(e?.target?.value)
                      }
                    }}
                    type="text"
                    name="gdc_id"
                    id="gdc_id"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                    placeholder="XXXXXXXXXXX"
                    required
                  />
                </div>
                <div className="w-1/2 flex justify-start items-start flex-col">
                  <label
                    htmlFor="role_selector"
                    className="block mb-2   font-medium text-gray-500 "
                  >
                    User Role
                  </label>
                  <select
                    name="role_selector"
                    id="role_selector"
                    className="w-full border border-gray-300 rounded-md text-xs p-2 invalid:text-gray-400"
                    value={userRole}
                    onChange={(e) => setUserRole(e?.target?.value)}
                  >
                    <option value="" disabled>Select Role</option>
                    {roles?.map((role, index) => (
                      <option
                        value={role?.role_id}
                        key={index + role?.role_name + role?.role_id}
                      >
                        {role?.role_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="w-full flex gap-4 justify-center items-center">
                <button
                  className="w-[90px] h-[35px] rounded-md outline-none text-xs text-white font-medium bg-green-500"
                  type='submit'
                  disabled={submitting}
                >
                   {submitting? 
               <svg aria-hidden="true" className="inline w-5 h-5 text-white animate-spin  fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
               :`Update`}
                </button>
                <button
                  className="w-[90px] h-[35px] rounded-md outline-none text-xs text-white font-medium bg-gray-900"
                  onClick={() => setEditUser({show:false, userData:{}})}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </dialog>
  )
}


const ManageUsers = () => {
    const [userList, setUserList] = useState([])
    const [editUser, setEditUser] = useState({show:false, userData:{}});
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem("userData"));

    const tableColumns = [
      {
        header : 'Full Name',
        accessorFn : row=> `${row.first_name} ${row.last_name}`
      },
      {
        header : 'Practice Name',
        accessorKey : 'practice_name'
      },
      {
        header : 'Email',
        accessorKey : 'email_id'
      },
      {
        header : 'Phone',
        accessorKey : 'phone_number'
      },
      {
        header : 'Action',
        accessorKey: 'action',
        cell : row=> (
          <div> 
            {/* className="bg-secondary px-2 rounded-full text-white w-full" */}
          <button className='mx-auto w-full flex justify-center'  onClick={()=>setEditUser({show:true, userData:row.row.original})}>
                  {/* <svg
                    className="w-6 h-6 text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 16"
                  >
                    <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z" />
                  </svg> */}
                  <svg className="w-6 h-6 text-center text-primary " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="square" stroke-linejoin="round" stroke-width="2" d="M7 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h1m4-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm7.441 1.559a1.907 1.907 0 0 1 0 2.698l-6.069 6.069L10 19l.674-3.372 6.07-6.07a1.907 1.907 0 0 1 2.697 0Z"/>
</svg>

            </button>
          {/* <button className="" onClick={()=> handleUserDelete(row?.row?.original)}>
              <svg
                className="w-6 h-6 text-gray-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 20"
              >
                <path d="M17 4h-4V2a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v2H1a1 1 0 0 0 0 2h1v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1a1 1 0 1 0 0-2ZM7 2h4v2H7V2Zm1 14a1 1 0 1 1-2 0V8a1 1 0 0 1 2 0v8Zm4 0a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0v8Z" />
              </svg>
            </button> */}
            </div>
        )
      }
    ]

    
    const table = useReactTable({
      data : userList,
      columns : tableColumns,
      getCoreRowModel: getCoreRowModel()
    })

    const fetchUsersList = () => {
        // const payload = {
          setLoading(false);
        //   id: 1,
        // };
        axios
          .get(`${process.env.REACT_APP_API_URL}api/v1/getAllUsers`, {withCredentials:true})
          .then((response) => {
            setLoading(false);
            console.log("received list of users: ", response?.data);
            setUserList(response?.data?.data?.data);
          })
          .catch((error) => {
            setLoading(false);
            console.log("Error while fetching users: ", error);
          });
    }

    useEffect(() => {
      if (!user?.token) {
        navigate("/login")
       }
    else if(user?.role_id!==1){
      navigate('/')
    }
       fetchUsersList() 
    },[])

    // if(!user?.token){
    //   navigate('/login')
    //   return;
    // }
    // if(user?.role_id !== 1 ){
    //   navigate('/')
    //   return;
    // }
    return (
      <div className="w-full bg-gray-100 p-4 select-none flex font-pop min-h-[calc(100vh-90px)]">
        <div className="w-full bg-white p-4 rounded-md shadow-lg flex flex-col gap-6">
          <div className="w-full flex flex-col items-start justify-start gap-7 min-h-[calc(100vh-300px)]">
            {setUserList?.length > 0 && (
              <div className="w-full justify-center items-center flex">
                <p className="text-lg font-medium">List of User Records</p>
              </div>
            )}
            <div className="w-full overflow-x-auto font-pop">
              {loading ? 
                <div className='flex justify-center w-full mt-20'>
                    <svg aria-hidden="true" className="inline w-7 h-7 text-primary animate-spin  fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                </div>
                  :userList?.length > 0 ? (
                // <table className="w-full rounded-md text-xs">
                //   <thead className="min-w-full   rounded-md">
                //     <tr className="rounded-md bg-gray-600 text-white">
                //       <th className="p-2 border border-gray-400 font-medium">
                //         Full Name
                //       </th>
                //       <th className="p-2 border border-gray-400 font-medium">
                //         Practice Name
                //       </th>
                //       <th className="p-2 border border-gray-400 font-medium">
                //         Email
                //       </th>
                //       <th className="p-2 border border-gray-400 font-medium">
                //         Phone
                //       </th>
                //       <th className="p-2 border border-gray-400 font-medium">
                //         Action
                //       </th>
                //     </tr>
                //   </thead>
                //   <tbody className="bg-white divide-y divide-gray-200">
                //     {userList?.map((user, index) => (
                //       <UserTableRow
                //         user={user}
                //         key={user?.id}
                //         index={index}
                //         fetchUsers={fetchUsersList}
                //       />
                //     ))}
                //   </tbody>
                // </table>
                <table className="w-full mx-auto">
                  <thead className="min-w-auto text-sm border-primary border">
                    {table.getHeaderGroups().map(headerGroup=> (
                      <tr key={headerGroup.id} className="rounded-t-md  bg-primary text-white text-sm divide-x divide-gray-400">
                        {headerGroup.headers.map(header=> (
                          <th key={header.id} className="py-[8px] px-2  text-start font-medium">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </th>
                        ))}
                      </tr>
                    ))

                    }
                  </thead>
                  <tbody className="bg-white divide-y divide-[#C0C0C0] border-b border-[#C0C0C0]">
                    {table.getRowModel().rows.map(row=> (
                      <tr key={row.id} className='divide-x divide-[#C0C0C0] border-x border-[#C0C0C0]  rounded-b-md text-start text-sm  hover:bg-[#dae1f072]'>
                        {row.getVisibleCells().map(cell=> (
                          <td key={cell.id} className=" py-[4px] px-2">
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="w-full flex justify-center items-center min-h-[300px]">
                  <p>You've not referred any patients yet.</p>
                </div>
              )}
              {editUser.show && <EditUserPopup editUser={editUser} setEditUser={setEditUser} fetchUsers={fetchUsersList}/>}
            </div>
          </div>
        </div>
      </div>
    );
}

export default ManageUsers